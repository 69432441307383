import { set } from 'date-fns';
import React, { useState, useEffect,  } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import {CREATE_SCHEDULE_EVENT_SUCCESS, getAssetCategories,getAssetsByCategory, scheduleNewEvent, SET_ERROR_FALSE , 
    readanCreatedEvent, getAllForms, updateScheduledEvent, UPDATE_SCHEDULE_EVENT_SUCCESS,
    getAssetsByCategoryAndFacility,
} from "../../Redux/Actions/Actions";
import { useSelector } from 'react-redux';
import SearchableDropdown from './searchableDropdown';
import { useDispatch } from "react-redux";
import Loader from '../Reusable_Components/loader';
import AlertPopUp from "../Reusable_Components/Alert";
import Button from 'react-bootstrap/Button';


const ScheduleEvent = ({ show, onHide, scheduleType, daysOfTheWeek, modalData,handleUpdateEventDetails, modifyFacilityFilter }) => {


    useEffect(() => {
        if (modalData && modalData?.event_id) {
            setSelectedFacility(modalData?.facility_name);
            setSelectedFacilityId(modalData?.facility_id);
            setSelectedEvent(modalData?.event_name);
            setSelectedAssetCategory(modalData?.event_entity_category);
            dispatch(getAssetsByCategoryAndFacility(modalData?.event_entity_category, modalData?.facility_id));

            setSelectedAsset(modalData?.event_entity_name);
            setAssetId(modalData?.event_entity_id);
            setAssetName(modalData?.event_entity_name);

            setEventDescription(modalData?.description);

            // setSelectedAssignee(modalData?.assignee);
            setSelectedSupervisor(modalData?.supervisor);
            setSelectedSop(modalData?.default_sop_id);
            setSelectedChecklist(modalData?.default_checklist_id);
            setSelectedSopName(modalData?.default_sop_name);
            setSelectedChecklistName(modalData?.default_checklist_name);

            setOneTimeEvent(modalData?.recurrence?.is_one_time);
            setOneTimeEventDate(modalData?.recurrence?.begin_date);
            setoneTimeEventStartTime(modalData?.recurrence?.event_start_time);
            setoneTimeEventEndTime(modalData?.recurrence?.event_end_time);
            // setSelectedCatergoryId(modalData.category.event_category_id);
        }
    }, [modalData]);

    const dispatch = useDispatch();
    const contractApiLoader = useSelector(state => state.contractApiLoader);
    const createNewEvent = useSelector(state => state.createNewEvent);
    const updateScheduledEventResponse = useSelector(state => state.updateScheduledEventResponse);

    const error = useSelector(state => state.error);
    const today = new Date().toISOString().split('T')[0];

    const [selectedFacility, setSelectedFacility] = useState(`${localStorage.getItem("selected_city_name")}, ${localStorage.getItem("selected_facility_name")}`);
    const [selectedScheduleType, setSelectedScheduleType] = useState(scheduleType[0]);
    const [selectedScheduleDay, setSelectedScheduleDay] = useState(daysOfTheWeek[1]);
    const [selectedEvent, setSelectedEvent] = useState(''); 
    const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
    const [selectedAsset, setSelectedAsset] = useState('');
    // const [selectedAssignee, setSelectedAssignee] = useState('');
    const [selectedSupervisor, setSelectedSupervisor] = useState('');
    const [selectedSop, setSelectedSop] = useState('');
    const [selectedChecklist, setSelectedChecklist] = useState('');
    const [selectedSopName, setSelectedSopName] = useState('');
    const [selectedChecklistName, setSelectedChecklistName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
  
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [isSingleAsset, setIsSingleAsset] = useState(true); //settinng single asst as defalt
    const [allAssets, setAllAssets] = useState(false);

    const [oneTimeEvent, setOneTimeEvent] = useState(true); //setting one time event as default
    const [recurringEvent, setRecurringEvent] = useState(false); //setting recurring event as default

    const assetCategory = useSelector(state => state.assetCategory);
    const eventCategory = useSelector(state => state.eventCategory); //storing all event categories from api
    const assetByCategory = useSelector(state => state.assetByCategory);
    
    // const usersList = useSelector(state => state.usersList);// for assignee.. original list 
    const facmanList = useSelector(state => state.facmanList);

    const [selectedCatergoryId, setSelectedCategoryId] = useState(null);
    const [eventCategoryList, setEventCategoryList] = useState([]);

    const [calendarDay, setCalendarDay] = useState(false);
    const [selectedDay, setSelectedDay] = useState('');
    
    const [weekDay, setweekDay] = useState(false);

    const [setDate, setSetDate] = useState(false);
    const [recurringBeginDate, setRecurringBeginDate] = useState('');
    const [recurringEndDate, setRecurringEndDate] = useState('');
    
    const [oneTimeEventDate, setOneTimeEventDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]);
    const [oneTimeEventStartTime, setoneTimeEventStartTime] = useState(new Date().toLocaleTimeString().split(' ')[0]);
    const [oneTimeEventEndTime, setoneTimeEventEndTime] = useState(new Date().toLocaleTimeString().split(' ')[0]);
    
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])

    const [checklistOptions, setChecklistOptions] = useState([]);
    const [sopOptions, setSopOptions] = useState([]);
    const [selectedSopId, setSelectedSopId] = useState(null);
    const [selectedChecklistId, setSelectedChecklistId] = useState(null);
    
    const [reminder, setReminder] =  useState({
        option: '30 minutes before',
        customValue: '',
        customUnit: 'minutes',
    });

    useEffect(() => {
        dispatch(getAllForms());
        dispatch(getAssetCategories());
    }, [dispatch]);

    const handleClose = () => {
        backFunction();
        setSelectedFacility(`${localStorage.getItem("selected_city_name")}, ${localStorage.getItem("selected_facility_name")}`);
        setEventDescription('');
        setSelectedEvent('');
        setSelectedAssetCategory('');
        setSelectedAsset('');
        // setSelectedAssignee('');
        setSelectedSupervisor('');
        setIsSingleAsset(true);
        setAllAssets(false);
        setSelectedSop('');
        setSelectedChecklist('');
        setOneTimeEvent(true);
        setRecurringEvent(false);
        setOneTimeEventDate(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]);
        setCalendarDay(false);
        setSelectedDay('');
        setweekDay(false);
        setRecurringBeginDate('');
        setRecurringEndDate('');
        setReminder({
            option: '30 minutes before',
            customValue: '',
            customUnit: 'minutes',
        });

        setAlertShow(false);
        setAlertType('');
        setAlertMessage('');
        setAlertValue('');
        setAlertButtons([]);
        modalData = null;
        onHide();
    };

    const formsList = useSelector(state => state.formsList);
    const [formList, setFormList] = useState([]);

    useEffect(() => {

        if (formsList) {
            setFormList(formsList);
        
        const sopOptionsList = formsList
        ?.filter(form => form.form_type === "sop")
        ?.map(form => 
            form.form_name
        ) || [];

    const checklistOptionsList = formsList
        ?.filter(form => form.form_type === "checklist")
        ?.map(form => 
            form.form_name
        ) || [];
        setSopOptions(sopOptionsList);
        setChecklistOptions(checklistOptionsList);
    }
    }, [formsList]);
   
    
   
    useEffect(() => {
        // setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type: SET_ERROR_FALSE,
                        payload: null
                    })
                    setAlertShow(false)
                }
            }])
        }
        // setIsLoading(false);
    }, [error])

    useEffect(() => {

        if(createNewEvent) {
            onHide();//
            // handleBack(); //
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue('Event Scheduled Successfully')
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type: CREATE_SCHEDULE_EVENT_SUCCESS,
                        payload: null
                    })
                    modifyFacilityFilter(selectedFacilityId);
                    dispatch(readanCreatedEvent(selectedFacilityId))
                    setAlertShow(false)
                    backFunction();
                }
            }])
        }
    }, [createNewEvent])

    useEffect(() => {
        if(updateScheduledEventResponse) {
            onHide();//
            // handleBack(); //
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue('Event Updated Successfully')
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type:  UPDATE_SCHEDULE_EVENT_SUCCESS,
                        payload: null
                    })
                    dispatch(readanCreatedEvent(selectedFacilityId))
                    setAlertShow(false)
                    backFunction();
                }
            }])
        }
    }, [updateScheduledEventResponse])

    
    const facilitiesListForNav = useSelector(state => state.facilitiesListForNav);
    const [facilityList, setFacilityList] = useState([]);
    const [selectedFacilityId, setSelectedFacilityId] = useState(localStorage.getItem("selected_facility_id"));
    useEffect(() => {
        if (facilitiesListForNav) {
            const options = facilitiesListForNav?.map((facility) => `${facility.city}, ${facility.name}`);
            setFacilityList(options);
            
        }
    }, [facilitiesListForNav]);

    useEffect(() => {
        if (eventCategory) {
            const categoryList = eventCategory?.map((category) => 
            category.category_name); 
            setEventCategoryList(categoryList);
        }
    }, [eventCategory]);
        
    const [supervisorList, setSupervisorList] = useState([]);
    useEffect(() => {
        if (facmanList) {
            const dataList = facmanList?.map((facman) =>
            facman.username);
            setSupervisorList(dataList);
        }
    }, [facmanList]);

    // const [assigneeList, setAssigneeList] = useState([]);
    // useEffect(() => {
    //     if (usersList) {
    //         const newList = usersList?.map((user) =>
    //         user.username);
    //         setAssigneeList(newList);
    //     }
    // }, [usersList]);
    
    const [assetsList, setAssetsList] = useState([]);
    const [assetId, setAssetId] = useState(null);
    const [assetName, setAssetName] = useState(null);

    useEffect(()    => {
        if (assetByCategory) {
            const assetList = assetByCategory?.map((asset) =>
            asset.asset_name);
            setAssetsList(assetList);
    }
    }, [assetByCategory]);

    const handleToggleChange = () => {
        setIsSingleAsset(!isSingleAsset);
        setAllAssets(!allAssets);
    };

    const OccurenceToggle = () => {
        setOneTimeEvent(!oneTimeEvent);
        setRecurringEvent(!recurringEvent);
    };
    
    const calendarDayToggle = () => {
        setCalendarDay(!calendarDay);
    };
    const weekDayToggle = () => {
        setweekDay(!weekDay);
    };

    const handleOptionChange = (event) => {
            const { value } = event.target;
            setReminder({
                option: value,
                customValue: '',
                customUnit: 'minutes',
            });
    };
    
    const handleCustomValueChange = (event) => {
            const { value } = event.target;
            setReminder({
                ...reminder,
                customValue: value,
            });
        };
    
    const handleCustomUnitChange = (event) => {
            const { value } = event.target;
            setReminder({
                ...reminder,
                customUnit: value,
            });
        };



    const handleCreateEvent = () => {
        const data = {
            event_name: selectedEvent,
            // event_entity_category: `${selectedAssetCategory},${assetId},${assetName}`,
            // event_entity_category: isSingleAsset ? `${selectedAssetCategory},${assetId},${assetName}` : `${selectedAssetCategory},all,all`,
            event_entity_category: selectedAssetCategory,
            event_entity_id: assetId,
            event_entity_name: assetName,

            description: eventDescription,
            status: "active",
            reminder_type: "email",
            reminder_before_hrs:  "48",  
            // assignee: selectedAssignee,
            supervisor: selectedSupervisor,
            facility_name: selectedFacility,
            facility_id: selectedFacilityId,
            default_sop_id: selectedSopId,
            default_checklist_id: selectedChecklistId,
            default_sop_name: selectedSopName,
            default_checklist_name: selectedChecklistName,

            "recurrence": {
            "is_one_time": oneTimeEvent,
            "begin_date" : oneTimeEventDate,// to b changed for recurring event
            "end_date" : oneTimeEventDate,// to b changed for recurring event
            "no_of_occurrences":1,
            "event_start_time": oneTimeEventStartTime,
            "event_end_time":   oneTimeEventStartTime,// to b changed for recurring event
            } ,

            "category": {
                event_category_id : selectedCatergoryId,
            }
        }
        dispatch(scheduleNewEvent(data));
    }

    const handleUpdateEvent = () => {
        const data = {
            event_id: modalData?.event_id,
            event_name: selectedEvent,
            event_entity_category: selectedAssetCategory,
            event_entity_id: assetId,
            event_entity_name: assetName,

            description: eventDescription,
            status: "active",
            reminder_type: "email",
            reminder_before_hrs:  "48",  
            // assignee: selectedAssignee,
            supervisor: selectedSupervisor,
            facility_name: selectedFacility,
            facility_id: selectedFacilityId,
            default_sop_id: selectedSopId,
            default_checklist_id: selectedChecklistId,
            default_sop_name: selectedSopName,
            default_checklist_name: selectedChecklistName,

            "recurrence": {
                "event_id": modalData?.event_id,
                "rule_id": modalData?.recurrence?.rule_id,
            "is_one_time": oneTimeEvent,
            "begin_date" : oneTimeEventDate,// to b changed for recurring event
            "end_date" : oneTimeEventDate,// to b changed for recurring event
            "no_of_occurrences":1,
            "event_start_time": oneTimeEventStartTime,
            "event_end_time":   oneTimeEventStartTime,// to b changed for recurring event
            } ,

            "category": {
                event_category_id : selectedCatergoryId,
            }
        }

      
        dispatch(updateScheduledEvent(data, handleUpdateEventDetails));

    }

    const backFunction = () => {
        setSelectedFacility(`${localStorage.getItem("selected_city_name")}, ${localStorage.getItem("selected_facility_name")}`);
        setEventDescription('');
        setSelectedEvent('');
        setSelectedAssetCategory('');
        setSelectedAsset('');
        // setSelectedAssignee('');
        setSelectedSupervisor('');
        setIsSingleAsset(true);
        setAllAssets(false);
        setSelectedSop('');
        setSelectedChecklist('');
        setOneTimeEvent(true);
        setRecurringEvent(false);
        setOneTimeEventDate(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]);
        setCalendarDay(false);
        setSelectedDay('');
        setweekDay(false);
        setRecurringBeginDate('');
        setRecurringEndDate('');
        setReminder({
            option: '30 minutes before',
            customValue: '',
            customUnit: 'minutes',
        });
        setAlertShow(false);
        setAlertType('');
        setAlertMessage('');
        setAlertValue('');
        setAlertButtons([]);
        setoneTimeEventStartTime(new Date().toLocaleTimeString().split(' ')[0]);
        setSelectedSopName('');
        setSelectedChecklistName('');
        onHide();

    }



    return (
        <>
        <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {contractApiLoader && <Loader />}
            <Modal
                keyboard={false}
                backdrop="static"
                show={show}
                className="schedule-mail-popup-modal"
                style={{
                    // width: '565px',
                    // top: '50%',
                    // left: '50%',
                    // transform: 'translate(-50%, -50%)',
                    display: 'block',
                    // height: '90vh',
                }}
                centered
                onHide={handleClose}
            >
                <Modal.Header >
                        <Modal.Title>Schedule Event</Modal.Title>
                    </Modal.Header>
                <Modal.Body>
                <div className="schedule-mail-popup">
                    <div className="schedule-mail-body">
                        <div className="schedule-mail-details-con">
                            <div>
                                <SearchableDropdown
                                    options = {facilityList? facilityList:[]} //facilityList is the array of facilities
                                    selectedOption = {selectedFacility}
                                    noOptionsAvailable = "Facility unavailable"
                                    onChange = {(e) => {
                                        setSelectedFacility(e.value)
                                        const selectedFacility = facilitiesListForNav?.find((facility) => `${facility.city}, ${facility.name}` === e.value); 
                                        setSelectedFacilityId(selectedFacility.facility_id);
                                        setAssetId("");  
                                        setAssetName("");
                                        setSelectedAssetCategory("")
                                        setSelectedAsset("");
                                    }}
                                    name={
                                        <span>
                                            Facility <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    } 
                                    placeholder = "Select Facility"
                                    isDisabled = {modalData && modalData?.event_id}
                                    isRequired = {true}
                                />
                            </div>
                            <div>
                                <SearchableDropdown
                                        // className = "searchable-dropdown"
                                        options = {eventCategoryList? eventCategoryList:[]} 
                                        selectedOption = {selectedEvent}
                                        noOptionsAvailable = "Category unavailable"
                                        onChange = {(e) => {
                                            setSelectedEvent(e.value)
                                            const selectedCatergory = eventCategory.find((category) => category.category_name === e.value);  
                                            setSelectedCategoryId(selectedCatergory.event_category_id);
                                        }}
                                        name={
                                            <span>
                                                Event <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        } 
                                        placeholder = "Select Event"
                                        isDisabled = {modalData && modalData?.event_id}
                                />
                            </div>
                            <div className="mt-3" style={{ display: 'flex', justifyContent:'space-between' }}>
                                    <div>
                                        <label className="switch">
                                            <input type="checkbox" checked={isSingleAsset} onClick={()=>{handleToggleChange()}} />
                                            <span className="slider round"></span>
                                        </label>
                                        <span className='mx-2'>Single Asset</span>
                                    </div>
                                
                                    <div>
                                        <label className="switch">
                                            <input type="checkbox" checked={allAssets} onClick={()=>{handleToggleChange()}}/>
                                            <span className="slider round"></span>
                                        </label>
                                        <span className='mx-2'>All Assets</span>
                                    </div>                              
                            </div>

                            <div className="mt-3">
                                    <SearchableDropdown
                                        // className = "searchable-dropdown"
                                        options = {assetCategory? assetCategory:[]} //assetCategory is the array of asset categories
                                        selectedOption = {selectedAssetCategory}
                                        noOptionsAvailable = "Category unavailable"
                                        onChange = {(e) => {
                                            setSelectedAssetCategory(e.value)  //note here we have to give e.value instead of e.target.value
                                            dispatch(getAssetsByCategoryAndFacility(e.value, selectedFacilityId));
                                            setSelectedAsset("");
                                            setAssetId("");  
                                            setAssetName("");

                                        }}
                                        name={
                                            <span>
                                                Asset Category <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        } 
                                        placeholder = "Select Asset Category"
                                        isDisabled = {modalData && modalData?.event_id}
                                />
                            </div>
                            
                        
                            {isSingleAsset && 
                                <div className='mt-3'>
                                    <SearchableDropdown
                                        // className = "searchable-dropdown"
                                         options = {assetsList} 
                                        selectedOption = {selectedAsset}
                                        noOptionsAvailable = "Asset unavailable"
                                        onChange = {(e) => {
                                            const asset =  assetByCategory.find((asset) => asset.asset_name === e.value);
                                            setSelectedAsset(e.value);
                                            setAssetId(asset.asset_id);  
                                            setAssetName(asset.asset_name);
                                        }}
                                        name={
                                            <span>
                                                Asset <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        } 
                                        placeholder = "Select Asset"
                                        isDisabled = {(modalData && modalData?.event_id) || !selectedAssetCategory}
                                    />
                                </div>
                            }                     
                            <div className="mt-3">
                                <p>Description</p>
                                <textarea
                                    id="textarea-input"
                                    placeholder="Enter text..."
                                    style={{
                                        width: '100%',padding: '8px',borderRadius: '5px', border: '1px solid #D9D9D9'
                                    }}
                                    value={eventDescription}
                                    maxLength={200}
                                    onChange={(e) => setEventDescription(e.target.value)}
                                ></textarea>
                                 <p style={{ fontSize: '12px', color: '#888' }}>
                                    {eventDescription.length}/200 characters
                                </p>
                            </div>
                            <div>
                            {/* <div className="mt-3">
                                <SearchableDropdown
                                    // className = "searchable-dropdown"
                                    options = {assigneeList} 
                                    selectedOption = {selectedAssignee}
                                    noOptionsAvailable = "Assignee unavailable"
                                    onChange = {(e) => {
                                        setSelectedAssignee(e.value)
                                    }}
                                    name = "Assignee"
                                    placeholder = "Select Assignee"
                                    isDisabled = {false}
                                />
                            </div>
                            <div className="mt-3">
                                <SearchableDropdown
                                    // className = "searchable-dropdown"
                                    options = {supervisorList}
                                    selectedOption = {selectedSupervisor}
                                    noOptionsAvailable = "Supervisor unavailable"
                                    onChange = {(e) => {
                                        setSelectedSupervisor(e.value)
                                    }
                                    }
                                    name = "Supervisor"
                                    placeholder = "Select Supervisor"
                                    isDisabled = {false}
                                />
                            </div> */}
                            {isSingleAsset && 
                                <div className="mt-3">
                                    <SearchableDropdown
                                    options = {sopOptions}
                                    selectedOption = {selectedSopName}
                                    noOptionsAvailable = "SOP unavailable"
                                    onChange = {(e) => {
                                        setSelectedSop(e.value)
                                        formsList?.map(form => {
                                            if (form.form_name === e.value) {
                                                setSelectedSopId(form.form_id)
                                                setSelectedSopName(form.form_name)
                                            }
                                        })
                                    }}
                                    name = "SOP"
                                    placeholder = "Select SOP"
                                    isDisabled = {false}
                                />
                                </div>}
                            {isSingleAsset && 
                            <div className="mt-3">
                               <SearchableDropdown
                                    options = {checklistOptions}
                                    selectedOption = {selectedChecklistName}
                                    noOptionsAvailable = "Checklist unavailable"
                                    onChange = {(e) => {
                                        setSelectedChecklist(e.value)
                                        formsList?.map(form => {
                                            if (form.form_name === e.value) {
                                                setSelectedChecklistId(form.form_id)
                                                setSelectedChecklistName(form.form_name)
                                            }
                                        })
                                    }}
                                    name = "Checklist"
                                    placeholder = "Select Checklist"
                                    isDisabled = {false}
                                />
                            </div>}
    {/* ************************************************************************************** */}
                            </div>
                            <div className = "mt-3"> 
                                <div style={{ display: 'flex'}}>
                                    <img src="images/icons/schedule.svg" alt="Schedule Icon" style={{ marginRight: '8px' }} />
                                    <p style = {{marginTop:"10px"}}>Schedule On</p>
                                </div>
                                <div>
                                    <div>
                                            <label className="switch">
                                                <input type="checkbox" checked={oneTimeEvent}  onClick={() => OccurenceToggle(true)}/>
                                                <span className="slider round"></span>
                                            </label>
                                            <span className='mx-2'>One Time Event</span>
                                    </div>
                                    {oneTimeEvent &&
                                        <div className="d-flex justify-content-between schedule-mail-inputs mt-4">
                                            <div>
                                                <p>Date</p>
                                                <input type="date" min={today} value={oneTimeEventDate} onChange={(e) => {
                                                    setOneTimeEventDate(e.target.value)
                                                }} style={{ width:'210px' , height:'45px' , marginTop:'-10px'}} />
                                            </div>
                                            <div>
                                                <p>Time</p>
                                                <input type="time" value ={oneTimeEventStartTime} onChange = {(e) => {
                                                    setoneTimeEventStartTime(e.target.value)
                                                }}
                                            style={{  width:'210px' ,height:'45px', marginTop:'-10px' }} />
                                            </div>
                                            
                                            
                                        </div>}
                                </div>
                                {/* recurring event div */}
                                <div>
                                    <div className='mt-3'>
                                            <label className="switch">
                                                <input type="checkbox" checked={recurringEvent}  onClick={() => OccurenceToggle(false)}/>
                                                <span className="slider round"></span>
                                            </label>
                                            <span className='mx-2'>Recurring Event</span>
                                    </div>
                                </div>
                               
                                {/* {recurringEvent && 
                                    <div className = 'recurring'>
                                        <div className='mt-3'>
                                                <label className="switch">
                                                    <input type="checkbox"  checked={calendarDay} onClick={()=>calendarDayToggle()}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className='mx-2'>Calendar Day based</span>
                                        </div>
                                        {calendarDay && (
                                            <div className='d-flex align-items-center ms-4 mt-3'>
                                                <select 
                                                    style={{
                                                        padding: '5px',borderRadius: '4px',
                                                        border: '1px solid #D9D9D9',marginRight: '10px'
                                                    }}
                                                    onChange={(e) => setSelectedDay(e.target.value)}
                                                >
                                                    <option value="Sunday">Sunday</option>
                                                    <option value="Monday">Monday</option>
                                                    <option value="Tuesday">Tuesday</option>
                                                    <option value="Wednesday">Wednesday</option>
                                                    <option value="Thursday">Thursday</option>
                                                    <option value="Friday">Friday</option>
                                                    <option value="Saturday">Saturday</option>
                                                </select>
                                                <span>of every month</span>
                                            </div>
                                        )} */}
                                        
                                        {/* <div className='mt-3'>  
                                                <label className="switch">
                                                    <input type="checkbox"  checked={weekDay} onClick={()=>weekDayToggle()}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className='mx-2'>Week Day based</span>
                                        </div>
                                        { weekDay &&
                                            <div className="schedule-type-day mt-3 ms-4">
                                                {daysOfTheWeek.map((each, index) => (
                                                    <p
                                                        key={index}
                                                        className={`${selectedScheduleDay === each ? 'selected C-pointer' : 'C-pointer'}`}
                                                        onClick={() => setSelectedScheduleDay(each)}
                                                    >
                                                        {each.slice(0,1)}
                                                    </p>
                                                ))}
                                            </div>} */}
                                            {/* <div className='mt-3'>  
                                                    <label className="switch">
                                                        <input type="checkbox" checked={weekDay} onClick={() => weekDayToggle()} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                {weekDay ? (
                                                    <div className="mt-2">
                                                        <select 
                                                            style={{
                                                                padding: '5px', 
                                                                borderRadius: '4px', 
                                                                border: '1px solid #D9D9D9',
                                                                marginBottom: '10px'
                                                            }}
                                                            onChange={(e) => setSelectedWeekOption(e.target.value)}
                                                        >
                                                            <option value="every-week">Every week</option>
                                                            {daysOfTheWeek.map((each, index) => (
                                                                <option key={index} value={each}>
                                                                    {each}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <span className='mx-2'>Week Day based</span>  
                                                )}
                                            </div>
                                            {weekDay && (
                                                <div className="schedule-type-day mt-3 ms-4">
                                                    <div className="week-days mt-2">
                                                        {daysOfTheWeek.map((each, index) => (
                                                            <p
                                                                key={index}
                                                                className={`${selectedScheduleDay === each ? 'selected C-pointer' : 'C-pointer'}`}
                                                                onClick={() => setSelectedScheduleDay(each)}
                                                            >
                                                                {each.slice(0, 1)} 
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                            <div className='mt-3'>
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className='mx-2'>Set Date</span>
                                            </div>
                                            <div className="d-flex justify-content-between schedule-mail-inputs mt-2 ms-4">
                                                <div>
                                                    <p>Begin Date</p>
                                                    <input type="date" min={today} value={recurringBeginDate} onChange={(e) => {
                                                        setRecurringBeginDate(e.target.value)}}
                                                     style={{ width: '180px', height:'45px' , marginTop:'-10px'}} />
                                                </div>
                                                <div>
                                                    <p>End Date</p>
                                                    <input type="date" min={today}
                                                     value={recurringEndDate} 
                                                     onChange={(e) => {
                                                        if (new Date(e.target.value) <= new Date(recurringBeginDate)) {
                                                            AlertPopUp("End date must be greater than begin date");
                                                        } else {
                                                            setRecurringEndDate(e.target.value);
                                                        }
                                                    }}
                                                     style={{ width: '180px', height:'45px' , marginTop:'-10px'}} />
                                                </div>
                                            </div>
                                            
                                            <div className='mt-3'>
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className='mx-2'>Repeat Until Cancelled</span>
                                            </div>

                                    </div>
                                } */}
                                
                            
                                <div className="d-flex justify-content-between mt-3">
                                    <div className="d-flex gap-2" style={{display:'flex', alignItems: 'center'}}>
                                        <img className="alarm" src="./images/icons/alarm.png" alt="upload" />
                                        <select value={reminder.option} onChange={handleOptionChange}
                                        className='set-reminder'>
                                            <option value="30 minutes before">30 minutes before</option>
                                            <option value="1 hour before">1 hour before</option>
                                            <option value="custom">Custom</option>
                                        </select>

                                        {reminder.option === 'custom' && (
                                            <div className='d-flex gap-3'>
                                                <input
                                                    className='set-reminder-input'
                                                    // style={{width:'40px', height:'25px',padding: '8px', borderRadius: '4px', border: '1px solid  #9D9D9C'}}
                                                    type="number"
                                                    value={reminder.customValue}
                                                    onChange={handleCustomValueChange}
                                                />
                                                <select value={reminder.customUnit} onChange={handleCustomUnitChange} className='responsive-set-reminder'>
                                                    <option value="minutes">minutes</option>
                                                    <option value="hours">hours</option>
                                                    <option value="days">days</option>
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* <div>
                                    <button className="schedule-create-button mt-3"
                                            onClick={handleCreateEvent}
                                    >Schedule Event</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                </Modal.Body>

                <Modal.Footer>
                        <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} 
                        onClick={handleClose}
                        >
                            Back
                        </Button>
                        {
                            modalData && modalData?.event_id ? (
                                <Button className='btn btn-secondary px-5'
                                onClick={handleUpdateEvent}
                            >
                            Update
                        </Button>
                            ) : (
                                <Button className='btn btn-secondary px-5'
                                onClick={handleCreateEvent}
                                disabled = {!selectedFacility || !selectedEvent || !selectedAssetCategory || !selectedAsset }
                            >
                            Save
                        </Button>
                            )
                        }
                        
                    </Modal.Footer>
            </Modal>
             {/* <div className="schedule-type-con mt-3">
                                    {scheduleType.map((each, index) => (
                                        <div key={index}>
                                            <p
                                                className={`${selectedScheduleType === each ? 'selected C-pointer' : 'C-pointer'}`}
                                                onClick={() => setSelectedScheduleType(each)}
                                            >
                                                {each}
                                            </p>
                                        </div>
                                    ))}
                                </div> */}
        </>
    );
};

export default ScheduleEvent;
