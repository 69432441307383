import React, { useMemo, useState,forwardRef, useImperativeHandle } from 'react'
import { timeElapsed } from './ReusableFunctions'
import { HiDotsHorizontal } from "react-icons/hi";
import { TbCircleCheckFilled } from "react-icons/tb";
// import { TbCircleLetterXFilled } from "react-icons/tb";
import { TbCircleXFilled } from "react-icons/tb";
import { set } from 'date-fns';
// import { timeElapsed } from './ReusableFunctions';

const TableComponent = forwardRef(({ headers, data, oppfunction1, maxHeightVal }, ref)=> {

    const [showButtons, setShowButtons] = useState(null);
    const [activeRow, setActiveRow] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortedData, setSortedData] = useState(data || []);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);

    useMemo(() => {
        if (data && Array.isArray(data)) {
            setSortedData([...data].sort((a, b) => b - a));
        } else {
            setSortedData([]);
        }
    }, [data]);

    // useMemo(() => {
    //     setSortedData([...data].sort((a, b) => b - a));
    // }, [data]);

    const handleSort = (header) => {
        const sortedData = [...data].sort((a, b) => {
            if (sortOrder === "asc") {
                if (typeof a[header.key] === "string" && typeof b[header.key] === "string") {
                    return a[header.key].localeCompare(b[header.key]);
                } else {
                    return a[header.key] - b[header.key];
                }
            } else {
                if (typeof a[header.key] === "string" && typeof b[header.key] === "string") {
                    return b[header.key].localeCompare(a[header.key]);
                } else {
                    return b[header.key] - a[header.key];
                }
            }
        });
        setSortedData(sortedData);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const handleRows = (header, row, index) => {
        if (header.type === "document") {
            return row[header?.key]?.split("/")[2]?.split("-")[0];
        } else if (header.type === "date" && row[header?.key] === null) {
            return "-";
        } else if (header.type === "time_stamp") {
            return timeElapsed(row[header?.key]);
        } else if (header.type === "button") {
            return (
                <div className='d-flex justify-content-center gap-2'>
                    {header.buttons.map((button, index) => (
                        <img key={index} src={button?.icon} alt={button?.name} role='button' onClick={() => button?.action(row)} />
                    ))}
                </div>
            );
        } else if (header.unitsKey) {
            return `${row[header.key]} ${row[header.unitsKey]}`;
        } else {
            return row[header.key];
        }
    };

            // if (header.buttons.length > 1) {
            //     return (
            //         <div>
            //             <HiDotsHorizontal fontSize={25} role='button' />
            //             <div className="button-list" onMouseLeave={() => setShowButtons(!showButtons)}>
            //                 {showButtons && activeRow === index && (
            //                     <div className="list-items">
            //                         {header.buttons.map((button, index) => {
            //                             return (
            //                                 <div key={index} className='d-flex align-items-center' onClick={() => button.action(row)}>
            //                                     <div style={{ width: "50%" }}><img src={button.icon} alt={button.name} /></div>
            //                                     <span key={index}>{button.name}</span>
            //                                 </div>
            //                             )
            //                         })
            //                         }

            //                     </div>
            //                 )}
            //             </div>
            //         </div>
            //     )
            // }
            // else {
            //     return <img src={header?.buttons[0]?.icon} alt={header?.buttons[0]?.name} role='button' onClick={() => header?.buttons[0]?.action(row)} />
            // }
    //     } else {
    //        
    //         return row[header?.key];
        
    //     }
    // }
    
    const clearEditables = () => {
        setSelectedRowIndex(null);
        setSelectedColumnIndex(null);
        setEditedValue(null);
    };

    const clearEditedField = () => {
        setEditedValue(originalValueSelectedForEdit);
        let dummySortedData = [...sortedData];
        dummySortedData[selectedRowIndex][headers[selectedColumnIndex]?.key] = originalValueSelectedForEdit;
        setSortedData(dummySortedData);

    }

    useImperativeHandle(ref, () => ({
        clearEditables,
        clearEditedField
    }));


    const [editedValue, setEditedValue] = useState(null);//edit value
    const [originalValueSelectedForEdit, setOriginalValueSelectedForEdit] = useState(null);
    
    const handleEditfunc = (rowIndex, columnIndex, value) => {
        if (headers[columnIndex].edit) {
            setOriginalValueSelectedForEdit(value)
            setSelectedRowIndex(rowIndex);
            setSelectedColumnIndex(columnIndex);
            // setEditedValue(sortedData[rowIndex][headers[columnIndex].key]);
            setEditedValue(value);
        }
    };
    const handleEditChange = (e) => {
        setEditedValue(e.target.value);//update value edited
    };

    const cancelEdit = ()=>{
        setSelectedRowIndex(null);  
        setSelectedColumnIndex(null);
        setEditedValue(null);
    };

    return (
        <>
            <table className='table_container' >
                <thead>
                    <tr className='fin_man_table_head commercial-head-table text-center' >
                    {headers?.map((header, index) => {
                            return (
                                <th key={index} role={header?.sort && 'button'}
                                    onClick={() => header.sort && handleSort(header)}>
                                    {header?.name} 
                                    {header.sort && <img src="./images/icons/sort.svg" alt="sort-icon" style={{ textAlign: 'center' }} />}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {sortedData && sortedData.length > 0 ? (
                        sortedData.map((row, rowIndex) => (
                            <tr key={rowIndex} 
                                className={`table_row ${rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}`} 
                                style={{ fontSize: "13px" }}>

                                {headers?.map((header, columnIndex) => (
                                    <td key={columnIndex}
                                        onClick={(e) => {
                                            if (header.type === "button") {
                                                setShowButtons(!showButtons);
                                                setActiveRow(rowIndex);
                                            }
                                        }}
                                        style={{ padding: "10px", width:'250px' }}
                                        className={`${(header.type === "number" || header.type === "date") ? 'text-end' : header.type === "button" ? "text-center" : 'text-start'}`}
                                    >
                                        {header.edit ? (header.edit(row) ? (
                                            <>
                                                {selectedRowIndex === rowIndex && selectedColumnIndex === columnIndex ? (
                                                    <>
                                                        <input 
                                                            // className = 'form-control'
                                                            type={header.type === 'number' ? 'number' : 'text'} 
                                                            onWheel={(e) => e.target.blur()}
                                                            value={editedValue} 
                                                            onChange={handleEditChange} 
                                                            style={{ width: '60%' }} 
                                                        />
                                                        {header.unitsKey && ` ${row[header.unitsKey]}`}
                                                        <TbCircleCheckFilled 
                                                            style={{ color: 'green', height: '30px', marginLeft: '5px', marginRight: '5px' }} 
                                                            onClick={() => {
                                                                oppfunction1(editedValue, rowIndex, columnIndex, row);
                                                            }}
                                                        />
                                                        <TbCircleXFilled 
                                                            style={{ color: 'red', height: '30px' }} 
                                                            onClick={cancelEdit} 
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <a style={{ textDecoration: 'underline' }}>
                                                            {row[header.key]}
                                                        </a>
                                                        {header.unitsKey && ` ${row[header.unitsKey]}`}
                                                        <img 
                                                            src="./images/icons/Edit.svg" 
                                                            alt="edit" 
                                                            role="button" 
                                                            style={{ marginLeft: '10px' }}
                                                            onClick={() => handleEditfunc(rowIndex, columnIndex, row[header.key])}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <span>{handleRows(header, row, rowIndex)}</span>
                                        )) : (
                                            /* Handling buttons */
                                            header.type === 'button' ? (
                                                header.buttons.map(button => (
                                                    <img 
                                                        key={button.name} 
                                                        src={button.icon} 
                                                        alt={button.name} 
                                                        onClick={() => button.action(row)} 
                                                        style={{ cursor: 'pointer' }} 
                                                    />
                                                ))
                                            ) : (
                                                <span>{handleRows(header, row, rowIndex)}</span>
                                            )
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={headers.length} style={{ textAlign: 'center', padding: '20px' }}>
                                No items in the table
                            </td>
                        </tr>
                    )}
                </tbody>

            </table >

        </>
    )
});

export default TableComponent;
