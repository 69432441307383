// serviceWorkerRegistration.js

// This optional code is used to register a service worker.
// register() is called only if process.env.NODE_ENV is 'production'.

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127\./)
  );
  
  export function register(config) {
    if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        if (isLocalhost) {
          checkValidServiceWorker(swUrl, config);
          navigator.serviceWorker.ready.then(() => {
            console.log('This PWA is served from localhost.');
          });
        } else {
          registerValidSW(swUrl, config);
        }
      });
    }
  }
  
  function registerValidSW(swUrl, config) {
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) return;
  
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log('New content is available; please refresh.');
                if (config?.onUpdate) config.onUpdate(registration);
              } else {
                console.log('Content is cached for offline use.');
                if (config?.onSuccess) config.onSuccess(registration);
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('Error during service worker registration:', error);
      });
  }
  
  function checkValidServiceWorker(swUrl, config) {
    fetch(swUrl, { headers: { 'Service-Worker': 'script' } })
      .then(response => {
        if (response.status === 404) {
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => window.location.reload());
          });
        } else {
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => console.log('No internet connection found.'));
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(registration => registration.unregister())
        .catch(console.error);
    }
  }
  