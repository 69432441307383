import React, { useEffect, useState } from 'react'
import WorkLog from '../Reusable_Components/Work_Log/WorkLog.jsx'
import NavBarTop from '../Reusable_Components/NavBarTop.jsx'
import LeftNavBar from '../Reusable_Components/LeftNavBar.jsx'
import FacilityManagerWorkLog from '../Facility_Manager/FacilityManagerWorkLog.jsx'

let data = {
    "PPM": [
        {
            id: 1,
            eventName: "Perform PPM on DG-1",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "10:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 2,
            eventName: "Perform PPM on DG-2",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "12:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 3,
            eventName: "Perform PPM on DG-3",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "02:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 4,
            eventName: "Perform Fire Drill at ACLC-2A",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "inprogress",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 5,
            eventName: "Perform PPM on Dry transformer-1",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "inprogress",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 6,
            eventName: "Perform PPM on Dry transformer-5",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2B",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 7,
            eventName: "Perform Meter Readings",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        }
    ],
    "SOP": [
        {
            id: 8,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "10:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 9,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "12:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 10,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "02:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 11,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2B",
            status: "inprogress",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 12,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 13,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 14,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 15,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 16,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 17,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        }
    ]
}


const facilitiesList = [
    {
        name: "ACL",
        id: 1,
        backgroundColor: "rgba(198, 237, 201)",
        color: "red"
    },
    {
        name: "ACLC",
        id: 2,
        backgroundColor: "rgba(226, 181, 227)",
        color: "green"
    },
    {
        name: "ACLC-2A",
        id: 3,
        backgroundColor: "rgba(96, 211, 230, 0.7)",
        color: "blue"
    },
    {
        name: "ACLC-2B",
        id: 4,
        backgroundColor: "rgba(228, 175, 118,0.7)",
        color: "yellow"
    },
]


const assignee_list = [
    {
        name: "Raj"
    },
    {
        name: "Rajesh"
    },
    {
        name: "Dinesh Karri"
    },
    {
        name: "Dinesh Dunna"
    },
    {
        name: "Akhil Yerramilli"
    },
    {
        name: "Bharat Ram"
    },
    {
        name: "Yashwanth palika"
    },
    {
        name: "Pavani Sreemukhi"
    },
    {
        name: "Yashwanth kumar"
    }
]

export default function TechnicianChecklist() {

    const [columnNames, setColumnNames] = useState([]);
    const [sections, setSections] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState({});
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [viewForm, setViewForm] = useState(false);

    useEffect(() => {
        // const getUniqueStatuses = (data) => {
        //     const statuses = new Set();
        //     Object.values(data).forEach(tasks => {
        //         tasks.forEach(task => {
        //             statuses.add(task.status);
        //         });
        //     });
        //     return Array.from(statuses);
        // };
        setColumnNames(["todo", "inprogress", "approval", "completed"]);
    }, []);

    useEffect(() => {
        setSections(Object.keys(data)?.map((each) => {
            return {
                tab: each,
                isOpen: false
            }
        }))
    }, [])

    useEffect(() => {
        const filteredTasks = Object.keys(data).reduce((acc, categoryKey) => {
            const categoryTasks = data[categoryKey];

            const filteredCategoryTasks = categoryTasks.filter(task => {
                const taskDate = new Date(task.scheduledDate.split('-').reverse().join('-'));
                const selectedDate = new Date(date);

                return taskDate.toDateString() === selectedDate.toDateString();
            });

            acc[categoryKey] = filteredCategoryTasks;
            return acc;
        }, {});

        setFilteredTasks(filteredTasks);
    }, []);

    const handelSectionChange = (tab) => {
        setSections(sections.map((section) => {
            if (section.tab === tab) {
                return { ...section, isOpen: !(section.isOpen) }
            } else {
                return section
            }
        }))
    }

    const handleUpdateStatus = (task, status) => {
        setFilteredTasks((prevTasks) => {
            const updatedTasks = { ...prevTasks };
            Object.keys(updatedTasks).forEach(categoryKey => {
                updatedTasks[categoryKey] = updatedTasks[categoryKey].map(t => {
                    if (t.id === task.id) {
                        return { ...t, status };
                    }
                    return t;
                });
            });
            return updatedTasks;
        });
    }

    const handleChangeDate = (e) => {
        setDate(e.target.value);
        const filteredTasks = Object.keys(data).reduce((acc, categoryKey) => {
            const categoryTasks = data[categoryKey];

            const filteredCategoryTasks = categoryTasks.filter(task => {
                const taskDate = new Date(task.scheduledDate.split('-').reverse().join('-'));
                const selectedDate = new Date(e.target.value);

                return taskDate.toDateString() === selectedDate.toDateString();
            });

            acc[categoryKey] = filteredCategoryTasks;
            return acc;
        }, {});

        setFilteredTasks(filteredTasks);
    }


    return (
        <FacilityManagerWorkLog Heading={"Checklist"} />
    )
}
