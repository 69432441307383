import React, { useEffect, useState } from 'react'
import { GoSearch } from "react-icons/go";
import { MdCurrencyRupee } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import { useDispatch, useSelector } from 'react-redux';
import { addNewLicensePermit, COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_SUCCESS, commercialHeadGetLicenseDocuments, commercialHeadUploadFacilityDocument, commercialHeadUploadLicenseDocument, CONTRACT_API_LOADER_TRIGGER_TRUE, finmanGetMeterReadings, getGstSubmissionLogs, handleDocumentLoaderFalse, handleDocumentLoaderTrue, handlePaginationLoaderTrue, LicensesAndPermitsAPI, PUT_CH_ADD_NEW_LICENSE_PERMIT_SUCCESS, PUT_CH_UPDATE_METER_SUCCESS } from '../../Redux/Actions/Actions';
import { set } from 'date-fns';
import Loader from '../Reusable_Components/loader';
import AlertPopUp from '../Reusable_Components/Alert';
import { validateValue } from "../Reusable_Components/ReusableFunctions";
import { SiVelog } from 'react-icons/si';
import Footer from "../Login/Footer";
import UploadComponent from '../Reusable_Components/UploadComponent';
import { IoDocumentTextOutline } from "react-icons/io5";
import axios from 'axios';
import { base_url } from '../../utilities';
import TableComponent from '../Reusable_Components/TableComponent';
import Pagination from '../Reusable_Components/Pagination';
import { RECORDS_PER_PAGE } from '../../Config';
import io from 'socket.io-client';


export default function LicensesAndPermits() {

    const socket = io.connect(base_url)

    const contractApiLoader = useSelector(state => state.contractApiLoader)
    const licensesAndPermitsDataList = useSelector(state => state.licensesAndPermitsData?.data)
    const currentPageNumber = useSelector(state => state.licensesAndPermitsData?.info?.page_number)
    const totalRecords = useSelector(state => state.licensesAndPermitsData?.info?.total_no_of_records)
    const newLicenseId = useSelector(state => state.newLicenseId)
    const [currentPage, setCurrentPage] = useState(1)
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({}) 
    const [selectMeter, setSelectMeter] = useState("all")
    const [sortOrder, setSortOrder] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const dispatch = useDispatch();
    const error = useSelector(state => state.error);
    const [isLoading, setIsLoading] = useState(false)
    //for alert pop up
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [alertAction, setAlertAction] = useState([])
    const [show, setShow] = useState(false);
    const [viewClicked, setViewClicked] = useState(false)
    const [licenseType, setLicenseType] = useState('')
    const [licenseName, setLicenseName] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [expiryReminder, setExpiryReminder] = useState('')
    const [renewal, setRenewal] = useState('')
    const [description, setDescription] = useState('')
    const [licenseTypeError, setLicenseTypeError] = useState(false)
    const [licenseNameError, setLicenseNameError] = useState(false)
    const [expiryReminderError, setExpiryReminderError] = useState(false)
    const [renewalError, setRenewalError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)
    // file upload states
    const [documentsListArray, setDocumentListArray] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState();
    const [showConfirmDeleteModel, setConfirmDeleteModel] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const [errorValue, setErrorValue] = useState(null)
    const [documentList, setDocumentList] = useState([]);
    const [filterLicenseType, setFilterLicenseType] = useState('')
    const [lovRenewal, setLovRenewal] = useState([]);
    const [lovLicenseType, setLovLicenseType] = useState([]);
    const [dummyflag, setDummyFlag] = useState(true)
    const commercialHeadUploadFacilityDocumentList = useSelector(state => state.commercialHeadUploadFacilityDocumentList);
    const commercialHeadGetFacilityDocumentsList = useSelector(state => state.commercialHeadGetFacilityDocumentsList);

    const sendMessage = () => {
        socket.emit('send_message', 'new license added palika');
    }

    useEffect(() => {
            socket.on('receive_message', (data) => {
                // if(data && dummyflag){
                sendNotification(data)
                setDummyFlag(false)
                // }
            })
            
    }, [socket])

    const fetchReports = (page) => {
        let condition
        if(filterLicenseType === ''){
            condition = JSON.stringify({
                facility_id: localStorage.getItem('selected_facility_id'),
            })
        }else{
            condition = JSON.stringify({
                facility_id: localStorage.getItem('selected_facility_id'),
                license_type : filterLicenseType,
            })
        }
        const data = {
            params : {
                search_text : searchTerm,
                page_number :page,
                items_per_page : RECORDS_PER_PAGE,
                filter_condition : condition,
            }
        };
        dispatch(LicensesAndPermitsAPI(data))
    }

    const requestNotificationPermission = async () => {
        if (Notification.permission === 'default') {
          await Notification.requestPermission();
        }
      };
    
      // Function to send the notification
      const sendNotification = (data) => {
        // Check if the current device is iOS
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      
        if (isIOS) {
          console.log('Notifications are not supported on iOS Chrome');
          return;
        }
      
        if (Notification.permission === 'granted' ) {
          setDummyFlag(false)
          new Notification(data, {
            body: 'This is a desktop notification from PORTOS notification.',
            icon: './images/icons/loginimage.jpg',
          });
        } else {
          console.log('Notification permission is not granted');
        }
      };
    
      useEffect(() => {
        requestNotificationPermission();
      }, []);

    const fetchRenewalValues = () => {
        axios.get(base_url + `listOfValues`,{
            params : {
                filter_condition : JSON.stringify({
                    lov_code: 'LICENSE_RENEWAL'
                })
            }
        })
        .then((response) => {
            setLovRenewal(response.data.data)
        })
        .catch((error) => {
            setErrorValue(error?.response?.data.message);
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                }
            }])
        })

    }

    const fetchLicenseTypeValues = () => {
        axios.get(base_url + `listOfValues`,{
            params : {
                filter_condition : JSON.stringify({
                    lov_code: 'LICENSE_TYPE'
                })
            }
        })
        .then((response) => {
            setLovLicenseType(response.data.data)
        })
        .catch((error) => {
            setErrorValue(error?.response?.data.message);
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                }
            }])
        })

    }

    useEffect(() => {
        console.log(newLicenseId)
        if (newLicenseId) {
        sendNotification()
            setShow(false)
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue('License Added Successfully')
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                    setShow(false)
                    backFunction()
                    dispatch({
                        type: PUT_CH_ADD_NEW_LICENSE_PERMIT_SUCCESS,
                        payload: null
                    })
                    fetchReports(currentPageNumber)
                    fetchLicenseTypeValues()
                    fetchRenewalValues()
                }
            }])
        }
    }, [newLicenseId]) 

    useEffect(() => {
        // setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message || error?.data?.data[1]?.message) {
            const errorMsg = error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message
            console.log(errorMsg)
            if(errorMsg.startsWith('Object already exists in S3 bucket')){
                setAlertShow(true)
                setAlertType('Question')
                setAlertMessage('Warning')
                setAlertValue('You already have a document with the same name. Do you want to replace it?')
                setAlertButtons([
                    {
                        name: 'Cancel', action: () => {
                            setAlertShow(false)
                            dispatch({
                                type: PUT_CH_UPDATE_METER_SUCCESS,
                                payload: null
                            })
                            // setDocumentListArray([])
                            setUploadedFiles([])
                        }
                    },
                    {
                        name: 'Duplicate', action: () => {
                            dispatch({
                                type: PUT_CH_UPDATE_METER_SUCCESS,
                                payload: null
                            })
                            setAlertShow(false)
                            const uploadedFilesData = [...uploadedFiles]
                            uploadedFilesData[0].flag = 'duplicate'
                            setUploadedFiles([...uploadedFilesData])
                            dispatch(handleDocumentLoaderTrue())
                            dispatch(commercialHeadUploadLicenseDocument(
                                {
                                    files: [uploadedFilesData[0]]
                                }
                            ))
                        }
                    }
                ])
                }else{
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        dispatch({
                            type: PUT_CH_UPDATE_METER_SUCCESS,
                            payload: null
                        })
                    }
                }])
            }
        }
        setIsLoading(false);
    }, [error])

    useEffect(() => {
        fetchReports(1)
        fetchLicenseTypeValues()
        fetchRenewalValues()
    }, [])

    useEffect(() => {
        if (licensesAndPermitsDataList?.length > 0) {
            setFilteredData([...licensesAndPermitsDataList])
        } else {
            setFilteredData([])
        }
    }, [licensesAndPermitsDataList])

    useEffect(() => {
        if (commercialHeadGetFacilityDocumentsList) {
            setDocumentListArray(commercialHeadGetFacilityDocumentsList)
            setIsLoading(false)
        }
    }, [commercialHeadGetFacilityDocumentsList])

    const backFunction = () => {
        setShow(false)
        setViewClicked(false)
        setLicenseType('')
        setLicenseName('')
        setStartDate('')
        setEndDate('')
        setExpiryReminder('')
        setRenewal('')
        setDescription('')
        console.log(`1111`)
        dispatch({
            type: COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_SUCCESS,
            payload: [],
        })
        console.log('2222')
    }

    const saveLicenesAndPermits = () => {
        const data = {
            "facility_id": localStorage.getItem('selected_facility_id'),
            "license_type": licenseType,
            "license_name": licenseName,
            "s3_key":   commercialHeadGetFacilityDocumentsList[0].s3_key,
            "start_date": startDate,
            "end_date": endDate,
            "expiry_reminder": expiryReminder,
            "renewal": renewal,
            "description": description,
            "username": localStorage.getItem('username')
        }
    dispatch(addNewLicensePermit(data))
    }

    const handleFilesSelected = (files) => {
        const processedFiles = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                const base64FileContent = reader.result.split(",")[1];
                const required_id = localStorage.getItem('selected_facility_id');
                const processedFile = {
                    file_content: base64FileContent,
                    object_type: "licenses_permits",
                    file_description: file.description,
                    file_type: file.file.type.split("/")[1],
                    file_name: file.file.name,
                    facility_id: required_id,
                    license_type: licenseType,
                    file_size: file.file.size,
                    flag: file.flag,
                    user_id: localStorage.getItem('username'),
                };
                processedFiles.push(processedFile);
                if (processedFiles.length === files.length) {
                    setUploadedFiles(processedFiles);
                }
            };
            reader.readAsDataURL(file.file);
        });
    };
    const confirmDeleteDocument = (index) => {
        setConfirmDeleteModel(true);
        setSelectedIndex(index);
    }

    const handleDocumentUpload = (index) => {
        dispatch(handleDocumentLoaderTrue())
        dispatch(commercialHeadUploadLicenseDocument(
            {
                files: [uploadedFiles[index]]
            }
        ))
    }

    const handleErrorFromDocument = (value) => {
        setErrorValue(value)
    }
    useEffect(() => {
        let documentListTemp = [...documentList];
        commercialHeadUploadFacilityDocumentList?.forEach(item => {
            if (!documentListTemp.includes(item)) {
                documentListTemp.push(item);
            }
        });
        setDocumentList([...documentListTemp]);
    }, [commercialHeadUploadFacilityDocumentList])

    const handleDocumentDelete = () => {
        dispatch(handleDocumentLoaderTrue())
        if (documentList.length > selectedIndex) {
            axios.delete(base_url + `documents`,
                {
                    data: {
                        s3_keys: [documentList[selectedIndex]?.key],

                    }
                }
            )
                .then((response) => {
                    if (response?.data?.data[0] === 'error') {
                        setErrorValue(response?.data?.data[1].message)
                        setAlertShow(true)
                        setAlertType('error')
                        setAlertMessage('Error')
                        setAlertValue(response?.data?.data[1].message)
                        setAlertButtons([
                            {
                                name: 'Close', action: () => {
                                    setAlertShow(false)
                                }
                            },
                        ])
                        setIsLoading(false)
                    }
                    else {
                        setConfirmDeleteModel(false)
                        dispatch(handleDocumentLoaderFalse())
                        let newDocumentList = [...documentList];
                        newDocumentList.splice(selectedIndex, 1);
                        setDocumentList(newDocumentList);
                    }
                })
                .catch((error) => {
                    setErrorValue(error?.response?.data.message);
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)
                })

        }
        else {
            setConfirmDeleteModel(false)
        }

    }

    const getDocuments = (id) => {
        dispatch(commercialHeadGetLicenseDocuments(id))
    }

    const handleDocDownload = (key) => {
        const data = {
            params: {
            filter_condition: 
                JSON.stringify({
                    s3_keys: [key]
                })
            
            }
        }
        dispatch({
            type : CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload : true,
        })
        axios.get(base_url + `documents`, data)
        .then((response) => {
            let data = response.data?.data[0]?.file;// Assuming the base64-encoded PDF is inside this path
            const dataObj = response.data?.data[0]
            if (!data) {
                return;
            }
            // Remove b' prefix and trailing '
            data = data.replace(/^b'|^b"|['"]$/g, '');
            // Create a data URL with base64 content
            const link = document.createElement('a');
            link.href = `data:application/${dataObj.file_type};base64,${data}`;
            link.setAttribute('download', dataObj.file_name); // Set the file name for download
            document.body.appendChild(link); // Append the link to the DOM
            link.click(); // Programmatically click the link to trigger the download
            link.remove(); // Remove the link after triggering download
            setIsLoading(false);
            dispatch({
                type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload : false,
            })
        })
        .catch((error) => {
            setIsLoading(false);
            dispatch({
                type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload : false,
            })
            if (error) {
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error ')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        
                    }
                }])
            }
            setIsLoading(false);
        });
    }

    // pagination finctions

    
    const handledPaginationNextClicked = () => {
        // handlePaginationLoaderTrue()
        fetchReports(currentPageNumber + 1)
        // dispatch(commercialHeadGetAllFacilities({ state: selectedStateFilter, city: selectedCityDropdown, search: searchTerm, limit: RECORDS_PER_PAGE, page: currentPaginationPage + 1 }))
    }

    const handledPaginationPrevClicked = () => {
        // handlePaginationLoaderTrue()
        fetchReports(currentPageNumber - 1)
        // dispatch(commercialHeadGetAllFacilities({ state: selectedStateFilter, city: selectedCityDropdown, search: searchTerm, limit: RECORDS_PER_PAGE, page: currentPaginationPage - 1 }))
    }

    

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            {isLoading && <Loader />}
            {contractApiLoader && <Loader />}
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {(currentPage === 1) && <div className='meter_container_reports '>
                <div className='fm-invoice-con'>
                    <div className='fm-invoice-heading-con  mb-1'>
                        <h3 className="invoice-heading">Licenses and Permits</h3>
                    </div>
                    <button className="invoice_btn" style={{ color: 'white' }}
                            onClick={() => {
                                setShow(true)
                            }}>Add New License +</button>
                </div>
                <div className='fm-invoice-con'>
                    <div className='fin-meter-reports-con'>
                    <div className='d-flex align-items-center gap-3 mb-3'>
                            <div style={{ width: '180px' }} className="fin_man_billing_period mt-2">
                            <p style={{ marginBottom: '5px' }}>License Type:&nbsp;</p>
                            <select
                                value={filterLicenseType}
                                onChange={(e) => {
                                    setFilterLicenseType(e.target.value);
                                }}
                                className="invoice-select-date"
                                style={{ width: "100%", height: "2.5rem" }}
                            >
                                <option hidden>Select</option>
                                {
                                    lovLicenseType.map((each) => {
                                        return <option value={each.lov_value}>{each.lov_value}</option>
                                    }
                                    )
                                }
                            </select>
                        </div>
                            <div className='fin_man_meter_search_bar_container'>
                                <input style={{ marginTop: '30px', height: '2.5rem' }} 
                                    type='search' placeholder='Search' 
                                    value={searchTerm}
                                    onChange={(e) => {
                                        const input = e.target.value
                                        setSearchTerm(input)
                                    }}
                                    className='fin_man_meter_search_bar' />
                                <GoSearch
                                    onClick={(e) => {
                                        
                                    }} 
                                    style={{ marginTop: '25px' }} className='fin_man_meter_search_bar_icon C-pointer' />
                            </div>

                        </div>

                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }} onClick={() => {
                            fetchReports(1)
                        }}>Apply</button>
                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }} onClick={() => {
                            setSearchTerm('')
                            setFilterLicenseType('')
                            let condition
                                condition = JSON.stringify({
                                    facility_id: localStorage.getItem('selected_facility_id'),
                                })
                            const data = {
                                params : {
                                    search_text : '',
                                    page_number :1,
                                    items_per_page : RECORDS_PER_PAGE,
                                    filter_condition : condition,
                                }
                            };
                            dispatch(LicensesAndPermitsAPI(data))
                            sendMessage()
                            }}>Clear</button>
                    </div>
                </div>
                {selectMeter && <div className='fin_meter_reports_table' style={{ height: '45vh' }}>
                <TableComponent 
                    headers={[
                        {name:"License Name", type:"text", key:"license_name", sort:true},
                        {name:"License Type ", type:"text", key:"license_type", sort:true},
                        {name:"End Date", type:"date", key:"end_date", sort:true},
                        {name:"Description", type:"text", key:"description", },
                        {name:"Created At", type:"time_stamp", key:"created_at", },
                        {name:"Updated By", type:"text", key:"updated_by", },
                        {name:"Document", type:"button", buttons:[
                            {name:"Document",
                            icon:"./images/icons/Document.svg",
                            action: (row) => {
                                handleDocDownload(row.s3_key)
                            }
                            },
                        ]},
                        {name:"View", type:"button", buttons:[
                                {name:"View",
                                icon:"./images/icons/ViewEYE.svg",
                                action: (each) => {
                                    const startDate = each.start_date.split('-').reverse().join('-')
                                    const endDate = each.end_date.split('-').reverse().join('-')
                                    setViewClicked(true)
                                    setLicenseType(each.license_type)
                                    setLicenseName(each.license_name)
                                    setStartDate(each.start_date)
                                    setEndDate(each.end_date)
                                    setExpiryReminder(each.expiry_reminder)
                                    setRenewal(each.renewal)
                                    setDescription(each.description)
                                    setShow(true)
                                    getDocuments(each.facility_id+each.license_type)
                                }
                                },
                            ]},
                        ]}
                        data={filteredData}
                />
                </div>}
                <Pagination
                    handledPaginationNextClicked={handledPaginationNextClicked}
                    handledPaginationPrevClicked={handledPaginationPrevClicked}
                    isApplyClicked
                    totalRecords={totalRecords}
                    recordsPerPage={RECORDS_PER_PAGE}
                    currentPaginationPage={currentPageNumber}
                />
            </div>}
            <Modal keyboard={false} backdrop="static" 
                show={show} 
                centered 
                onHide={() => setShow(false)} 
                size='lg'>
                    <Modal.Header >
                        <Modal.Title>{viewClicked?'License Details':'Add New License'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex flex-column gap-3'>
                        <div className='d-flex justify-content-between gap-3'>
                        <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>License Name{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                            <input type='text'
                                                disabled={viewClicked}
                                                className={`form-control add-facility-input-fields `}
                                                value={licenseName}
                                                onChange={(e)=>{
                                                    const input  = e.target.value
                                                    setLicenseName(input)
                                                    if(input.length < 3 || input.length > 200 ){
                                                        if(input.length === 0){
                                                            setLicenseNameError(false)}
                                                        else{
                                                            setLicenseNameError(true)}
                                                    }else{
                                                        setLicenseNameError(false)
                                                    }
                                                }}
                                                required
                                            />

                                            {/* {facilityNameError && <div className="invalid-input">Only a-z,A-Z,0-9,spaces,-,_,/ are allowed.</div>}
                                            {uniqueFacilityNameError && <div className="invalid-input">Facility name should be unique!</div>}*/}
                                            {licenseNameError && <div className="invalid-input">Must be between 3 to 200 characters</div>} 
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>License Type{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                            <select className='commercial-head-dropdown' 
                                                disabled={viewClicked}
                                                value={licenseType}
                                                onChange={(e)=>{
                                                    setLicenseType(e.target.value)
                                                }}
                                                required>
                                                        <option hidden>Select</option>
                                                        {lovLicenseType.map((each) => {
                                                            return <option value={each.lov_value}>{each.lov_value}</option>

                                                        }
                                                        )}
                                            </select>
                                        </div>
                                    </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>Start Date{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                            <input type='date'
                                            disabled={viewClicked}
                                                className={`form-control add-facility-input-fields `}
                                                value={startDate ? startDate : ''}
                                                onChange={(e)=>{
                                                    setStartDate(e.target.value)
                                                    setLicenseTypeError(false)
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>End Date{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                            <input type='date'
                                            disabled={viewClicked}
                                                className={`form-control add-facility-input-fields `}
                                                value={endDate ? endDate : ''}
                                                min={startDate}
                                                onChange={(e)=>{
                                                    setEndDate(e.target.value)
                                                }}
                                                required
                                            />

                                            {/* {facilityNameError && <div className="invalid-input">Only a-z,A-Z,0-9,spaces,-,_,/ are allowed.</div>}
                                            {uniqueFacilityNameError && <div className="invalid-input">Facility name should be unique!</div>}
                                            {lenghtError && <div className="invalid-input">Must be between 3 to 200 characters</div>} */}
                                        </div>
                                    </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>Expiry Reminder{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                        <select className='commercial-head-dropdown' 
                                        disabled={viewClicked}
                                        value={expiryReminder}
                                        onChange={(e)=>{
                                            setExpiryReminder(e.target.value)
                                        }}
                                        required>
                                                <option hidden>Select</option>
                                                <option value='1 Month'>1 Month</option>
                                                <option value='2 Months'>2 Months</option>
                                                <option value='3 Months'>3 Months</option>
                                                <option value='6 Months'>6 Months</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>Renewal{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                        <select className='commercial-head-dropdown' 
                                        disabled={viewClicked}
                                        value={renewal}
                                        onChange={(e)=>{
                                            setRenewal(e.target.value)
                                        }}
                                        required>
                                                <option hidden>Select</option>
                                                {lovRenewal.map((each) => {
                                                    return <option value={each.lov_value}>{each.lov_value}</option>
                                                }
                                                )}
                                            </select>
                                        </div>
                                    </div>
                            </div>
                            <div className='d-flex flex-column justify-content-between gap-2 align-items-start'>
                                <label className='add-facility-modal-label'>Documents{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                <UploadComponent
                                    onFilesSelected={handleFilesSelected}
                                    uploadMethod="single"
                                    viewMode={viewClicked || licenseType == ''}
                                    uploadedFileData={documentsListArray}
                                    uploadActionClicked={handleDocumentUpload}
                                    deleteSpecificFileMain={confirmDeleteDocument}
                                    maxNumberOfFiles={1}
                                    handleErrorFromDocument={handleErrorFromDocument}
                                    idType={"other"}
                                    acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                />
                            </div>
                            <div className='d-flex flex-column justify-content-between gap-2 align-items-start'>
                                <label className='add-facility-modal-label'>Description:</label>
                                <textarea disabled={viewClicked} className='form-control' required style={{ border: "0.5px solid #9D9D9C",width:'100%' }} value={description} 
                                onChange={(e)=>{
                                    setDescription(e.target.value)
                                }}
                                 />
                            </div>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} 
                        onClick={() => {
                            backFunction()
                        }}
                        >
                            Back
                        </Button>
                        {!viewClicked && <Button className='btn btn-secondary px-5' 
                        onClick={()=>{
                            saveLicenesAndPermits()
                        }} 
                        disabled={!licenseName || !licenseType || !startDate || !endDate || !expiryReminder || !renewal ||  licenseNameError || !commercialHeadGetFacilityDocumentsList}
                        >
                            Save
                        </Button>}
                    </Modal.Footer>
                </Modal>
            <Footer />
        </>
    )
}
