import { addMonths, differenceInDays, endOfMonth,  startOfMonth , getWeek, getWeekOfMonth, max} from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { IoMdClose} from "react-icons/io";
import { IoArrowUpSharp ,IoArrowDown} from "react-icons/io5";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import {Modal,Button,Dropdown} from 'react-bootstrap';
import FinanceManagerDayCalendar from "../Finance_Manager/FinanceManagerDayCalendar";
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import ScheduleEvent from "../Reusable_Components/ScheduleEvent";
import { getAssetCategories, getEventCategories , getAllUsers, getFacmanfromUsers, commercialHeadGetAllFacilitiesForNav, readanCreatedEvent} from "../../Redux/Actions/Actions";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import SearchableDropdown from './searchableDropdown';
import TableComponent from "./TableComponent";
import Select from 'react-select';
import Loader from '../Reusable_Components/loader';
import Footer from "../Login/Footer.jsx"
import { useNavigate } from "react-router-dom";
import { base_url } from "../../utilities.jsx";
import axios from "axios";

const Calender = (props) => {
    const contractApiLoader = useSelector(state => state.contractApiLoader);
    const navigate = useNavigate()

    const availableOption = ["Year","Month","Week","Day"]
    const daysOfTheWeek  =  ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
    const monthsOfYear =    ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const scheduleType =    ['Weekly','Monthly','Quarterly']
    const timeArray = [ {time:'01:00 am',value:1},
        {time:'02:00 am',value:2},
        {time:'03:00 am',value:3},
        {time:'04:00 am',value:4},
        {time:'05:00 am',value:5},
        {time:'06:00 am',value:6},
        {time:'07:00 am',value:7},
        {time:'08:00 am',value:8},
        {time:'09:00 am',value:9},
        {time:'10:00 am',value:10},
        {time:'11:00 am',value:11},
        {time:'12:00 pm',value:12},
        {time:'01:00 pm',value:13},
        {time:'02:00 pm',value:14},
        {time:'03:00 pm',value:15},
        {time:'04:00 pm',value:16},
        {time:'05:00 pm',value:17},
        {time:'06:00 pm',value:18},
        {time:'07:00 pm',value:19},
        {time:'08:00 pm',value:20},
        {time:'09:00 pm',value:21},
        {time:'10:00 pm',value:22},
        {time:'11:00 pm',value:23},
        {time:'12:00 am',value:23},
    ]
    const dispatch = useDispatch()
    const [hourNow, setHourNow] = useState(new Date().getHours())
    const [minutesNow, setMinutesNow] = useState(new Date().getMinutes())
    const [currentOption,setCurrentOption] = useState("Month")
    const [currentPage,setCurrentPage] = useState(2)
    const [currentDay,setCurrentDate] = useState(new Date())
    const [displayTodayDate,setDisplayTodayDate] = useState("")
    const [currentWeek,setCurrentWeek] = useState(0)
    const [weeksData,setWeeksData] = useState([])
    const [modalShow,setModalShow] = useState(false)
    const [showModal,setShowModal] = useState(false)
    const [showWeeklyPopUp,setShowWeeklyPopUp] = useState(false)
    const [weeklyPopupDate,setWeeklyPopupDate] = useState(0)
    const [weeklyPopupDay,setWeeklyPopupDay] = useState("")
    const [weeklyPopUpData,setWeeklyPopUpData] = useState([])
    const [popupDate,setpopupDate] = useState(0)
    const [popupDay,setPopupDay] = useState("")
    const [popupData,setPopupData] = useState([])
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedScheduleType,setScheduleType] = useState(scheduleType[0])
    const [selectedScheduleDay,setScheduleDay] = useState(daysOfTheWeek[1])
    const [tasksData,setTasksData] = useState(props.tasksData)
    const [startX, setStartX] = useState(null);
    const currentTimeRef = useRef(null);

    const [date,setDate] = useState(currentDay.getDate())

    //month calender inputs
    const [currentMonth,setCurrentMonth] = useState(currentDay.getMonth())
    const [currentYear,setCurrentYear] = useState(currentDay.getFullYear())
    const [startDate,setStartDate] = useState(startOfMonth(currentDay))
    const [endDate,setEndDate] = useState(endOfMonth(currentDay))
    const [numDays,setNumDays] = useState(differenceInDays(endDate,startDate)+1)
    const [prefixDays,setPrefixDays] = useState(startDate.getDay())
    const [suffixDays,setSuffixDays] =  useState(6 - endDate.getDay())
    const [day,setDay] = useState(new Date(currentYear,currentMonth,date).getDay())
    const [dayCalendarTasks,setDayCalendarTasks] = useState([])
    const getEventList = useSelector(state => state.getEventList)

    const [eventCategoryList, setEventCategoryList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState([]); 
    const eventCategory = useSelector(state => state.eventCategory);

    const [tasksPopup, setTasksPopup] = useState(false);
    const [selectedTaskList, setSelectedTaskList] = useState([]);
    const [selectedTask, setSelectedTask] = useState('');
    const [selectedEventCategory, setSelectedEventCategory] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('')

    const [editEvent, setEditEvent] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState('');
    const [eventDetails, setEventDetails] = useState({});

    const [alertShow, setAlertShow] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertValue, setAlertValue] = useState('');
    const [alertButtons, setAlertButtons] = useState([]);

    const [selectedEvents, setSelectedEvents] = useState([]);
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);

    useEffect(() => {
        dispatch(getEventCategories());
        dispatch(commercialHeadGetAllFacilitiesForNav())
    }, [dispatch]);

    useEffect(() => {
        if (eventCategory) {
            let categoryList = ["All"];
            
            eventCategory?.map((category) => 
                categoryList.push(category.category_name));

            setEventCategoryList(categoryList);
            // setSelectedEvent(categoryList);
        }
    }, [eventCategory]);

    const eventCategoryOptions = [{ label: 'All', value: 'All' }, ...eventCategoryList.map(category => ({
    label: category.category_name,
    value: category.category_name
}))];

    const [facilityList, setFacilityList] = useState([]);
    const [selectedfacility, setSelectedFacility] = useState(`${localStorage.getItem("selected_city_name")}, ${localStorage.getItem("selected_facility_name")}`);
    const facilitiesListForNav = useSelector(state => state.facilitiesListForNav);
    const [selectedFacilityId, setSelectedFacilityId] = useState(localStorage.getItem("selected_facility_id"));
    
    const [isFacilitySelected, setIsFacilitySelected] = useState(selectedfacility); 
    

    useEffect(() => {
        if (facilitiesListForNav) {
            const options = facilitiesListForNav?.map((facility) => `${facility.city}, ${facility.name}`);
            setFacilityList(options);
            
        }
    }, [facilitiesListForNav]);


    useEffect(() => {
        if (getEventList) {
            const data = getEventList;
            const taskData = {};
    
            data?.forEach((each) => {
                const { begin_date, end_date } = each?.recurrence || {};
    
                // Convert dates to Date objects
                const startDate = new Date(begin_date);
                const endDate = new Date(end_date);
    
                // Check if dates are valid
                if (!isNaN(startDate) && !isNaN(endDate)) {
                    // Generate all dates between begin_date and end_date
                    for (
                        let date = new Date(startDate);
                        date <= endDate;
                        date.setDate(date.getDate() + 1)
                    ) {
                        // Format the date as 'YYYY-MM-DD'
                        const formattedDate = date.toISOString().split('T')[0];
    
                        // Add the event to taskData for the current date
                        taskData[formattedDate] = taskData[formattedDate]
                            ? [...taskData[formattedDate], each]
                            : [each];
                    }
                }
            });
    
            setTasksData(taskData);
        }
    }, [getEventList]);
    

    useEffect(()=>{

        const facilityId = localStorage.getItem("selected_facility_id")
        dispatch(readanCreatedEvent(facilityId,selectedEventCategory))

        const month = new Date()
        const presentMonth = month.getMonth()
        const timer = setInterval(()=>{
            setHourNow(new Date().getHours())
            setMinutesNow(new Date().getMinutes())
        }, 60000);
        displayToday()
        setDayCalendarTasks(tasksData[currentYear+"-"+(currentMonth+1)+"-"+date])
        createWeekDataFunc()
        setDate(currentDay.getDate())
        setDay(new Date(month.getFullYear(),presentMonth,month.getDate()).getDay())
        setDayCalendarTasks(tasksData[month.getFullYear()+"-"+(presentMonth+1)+"-"+month.getDate()])
        return () => clearInterval(timer);
        setSelectedFacility('');
        
    },[])  

    useEffect(()=>{
        if (currentOption !== "Day") {
            displayTodayMonth()
        }
        const currentTimeString = new Date().getHours();
        const targetDiv = document.getElementById(currentTimeString);
        if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    },[currentOption])

    useEffect(()=>{
       if(currentPage===2){
        setCurrentDate(new Date())
        createWeekDataFunc()
       }
    },[currentPage])
    

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const changeCurrentPage = (each) => {
        setCurrentOption(each)
        const option = availableOption.indexOf(each)
        if(option===2){
            displayTodayMonth()
        }
        setCurrentPage(option+1)
    }

    const displayToday = () => {
        const today = new Date()
        const todayDate = today.getDate()
        setDisplayTodayDate(todayDate+" "+monthsOfYear[today.getMonth()]+" "+today.getFullYear())
    }

    const mapCallenderMonth = (nextMonth) => {
        setCurrentDate(nextMonth)
        setCurrentMonth(nextMonth.getMonth())
        setCurrentYear(nextMonth.getFullYear())
        const startDate = startOfMonth(nextMonth)
        const endDate = endOfMonth(nextMonth)
        setStartDate(startDate)
        setEndDate(endDate)
        setNumDays(differenceInDays(endDate,startDate)+1)
        setPrefixDays(startDate.getDay())
        setSuffixDays(6 - endDate.getDay())
    }

    const displayNextMonth = () => {
        if (currentOption === "Day") {
            setDay(new Date(currentYear,currentMonth,date).getDay()+1)
            if (String(endDate).split(" ")[2] == date) {
                const nextMonth = addMonths(currentDay, 1)
                mapCallenderMonth(nextMonth)
                setDate(1)
                setDayCalendarTasks(tasksData[nextMonth.getFullYear()+"-"+(currentMonth+2)+"-"+1])
            }else{
                setDate(parseInt(date+1))
                setDayCalendarTasks(tasksData[currentYear+"-"+(currentMonth+1)+"-"+(date+1)])
            }
        }else{
            const nextMonth = addMonths(currentDay, 1)
            mapCallenderMonth(nextMonth)
            setDay(new Date(currentYear,currentMonth+1,date).getDay())
        }
    }

    const displayPreviousMonth = () => {
        if (currentOption === "Day") {
            if (new Date(currentYear,currentMonth,date).getDay()-1 === -1) {
                setDay(6)    
            }else{
                setDay(new Date(currentYear,currentMonth,date).getDay()-1)
            }
            if (String(startDate).split(" ")[2] == date) {
                const nextMonth = addMonths(currentDay, -1)
                setDayCalendarTasks(tasksData[nextMonth.getFullYear()+"-"+(nextMonth.getMonth()+1)+"-"+(endOfMonth(nextMonth).getDate())])
                setDate(endOfMonth(nextMonth).getDate())
                mapCallenderMonth(nextMonth)    
            }else{
                setDate(parseInt(date-1))
                setDayCalendarTasks(tasksData[currentYear+"-"+(currentMonth+1)+"-"+(date-1)])
            }
            // setDate(parseInt(date-1))
        }else{
            const nextMonth = addMonths(currentDay, -1)
            mapCallenderMonth(nextMonth)
            setDay(new Date(currentYear,currentMonth-1,date).getDay())
        }
    }

    const displayTodayMonth = () => {
        const month = new Date()
        const presentMonth = month.getMonth()
        setDayCalendarTasks(tasksData[month.getFullYear()+"-"+presentMonth+1+"-"+month.getDate()])
        setDay(new Date(month.getFullYear(),presentMonth,month.getDate()).getDay())
        setDate(currentDay.getDate())
        displayToday()
        mapCallenderMonth(month)
        createWeekDataFunc() 
        const currentTimeString = new Date().getHours();
        const targetDiv = document.getElementById(currentTimeString);
        if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    const isToday = (each) => {
        const date = new Date()
        const today = date.getDate()
        const presentMonth = date.getMonth()
        if(each === today && presentMonth === currentMonth ){
            return true
        }else{
            return false
        }
    }
 
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const setPerDayModal = (each) => {
   
    const selectedDate = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(each).padStart(2, '0')}`;
    const selectedDay = daysOfTheWeek[(new Date(selectedDate)).getDay()]
    
    const data  = tasksData[selectedDate]
    
    setpopupDate(each)
    setPopupDay(selectedDay)
    setPopupData(data)
    setShowModal(true)
  }

  const createWeekDataFunc = () => {
    const totalDays = []
    const nextMonth = addMonths(currentDay, -1)
    const endDate1 = endOfMonth(nextMonth).getDate()
    const prefix = (Array.from(Array(prefixDays).keys())).reverse()
    const numdays = Array.from(Array(numDays).keys())
    const suffix =  Array.from(Array(suffixDays).keys())
    prefix?.map((each)=>{
        totalDays.push(endDate1-each)
    })
    numdays.map(each=>{
        totalDays.push(each+1)
    })
    suffix.map(each=>{
        totalDays.push(each+1)
    })
    const chunkSize = 7;
    const result = [];
    for (let i = 0; i < totalDays.length; i += chunkSize) {
    const chunk = totalDays.slice(i, i + chunkSize);
    result.push(chunk);
    }
    setWeeksData(result)
    setCurrentWeek(getWeekOfMonth(currentDay)-1)
  }

  const dayCalendarView = (each) => {
    setDayCalendarTasks(tasksData[each])
    setCurrentPage(4)
    setCurrentOption("Day")
    setDate(parseInt(each.split("-")[2]))
    setDay(new Date(each).getDay())
  }

  const displayPreviousWeek = () =>{
    const currentWeekData = currentWeek -1
    if(currentWeekData <=0){
        changeMonthWeek(-1)
    }else{
        setCurrentWeek(currentWeekData)
    }
  }

  const displayNextWeek = () =>{
    const currentWeekData = currentWeek + 1
    if(currentWeekData > weeksData.length-1){
        changeMonthWeek(1)
    }else{
        setCurrentWeek(currentWeekData)
    }
  }

  const handleWeekToDayCalendar = (index) => {
    if (currentWeek === 0) {
        if (weeksData[currentWeek][index] > 20) {
            if (currentMonth === 0) {
                // console.log((currentYear-1)+"-"+(12)+"-"+weeksData[currentWeek][index])
                setDayCalendarTasks(tasksData[(currentYear)+"-"+(12)+"-"+weeksData[currentWeek][index]])
                setCurrentYear(currentYear-1)
                setDay(new Date(currentYear-1,11,weeksData[currentWeek][index]).getDay())
                setDate(weeksData[currentWeek][index])
                setCurrentMonth(new Date(currentYear,currentMonth-1,weeksData[currentWeek][index]).getMonth())
                setCurrentPage(4)
                setCurrentOption("Day")
            }else{
                // console.log((currentYear)+"-"+(currentMonth)+"-"+weeksData[currentWeek][index])
                setDayCalendarTasks(tasksData[currentYear+"-"+(currentMonth)+"-"+weeksData[currentWeek][index]])
                setDay(new Date(currentYear,currentMonth-1,weeksData[currentWeek][index]).getDay())
                setDate(weeksData[currentWeek][index])
                setCurrentMonth(new Date(currentYear,currentMonth-1,weeksData[currentWeek][index]).getMonth())
                setCurrentPage(4)
                setCurrentOption("Day")
            }
        }else if (weeksData[currentWeek][index] < 10) {
            // console.log((currentYear)+"-"+(currentMonth+1)+"-"+weeksData[currentWeek][index])
            setDayCalendarTasks(tasksData[currentYear+"-"+(currentMonth+1)+"-"+weeksData[currentWeek][index]])
            setDay(new Date(currentYear,currentMonth,weeksData[currentWeek][index]).getDay())
            setDate(weeksData[currentWeek][index])
            setCurrentMonth(new Date(currentYear,currentMonth,weeksData[currentWeek][index]).getMonth())
            setCurrentPage(4)
            setCurrentOption("Day")
        }
    }
    else if ((currentWeek == weeksData.length-1)) {
        if (weeksData[currentWeek][index] < 10 ) {
            if (currentMonth === 11) {
                // console.log((currentYear+1)+"-"+(1)+"-"+weeksData[currentWeek][index])
                setDayCalendarTasks(tasksData[(currentYear+1)+"-"+(1)+"-"+weeksData[currentWeek][index]])
                setCurrentYear(currentYear+1)
                setDay(new Date(currentYear+1,0,weeksData[currentWeek][index]).getDay())
                setDate(weeksData[currentWeek][index])
                setCurrentMonth(new Date(currentYear,currentMonth+1,weeksData[currentWeek][index]).getMonth())
                setCurrentPage(4)
                setCurrentOption("Day")
            }else{
                // console.log((currentYear)+"-"+(currentMonth+2)+"-"+weeksData[currentWeek][index])
                setDayCalendarTasks(tasksData[currentYear+"-"+(currentMonth+2)+"-"+weeksData[currentWeek][index]])
                setCurrentYear(currentYear)
                setDay(new Date(currentYear,currentMonth+1,weeksData[currentWeek][index]).getDay())
                setDate(weeksData[currentWeek][index])
                setCurrentMonth(new Date(currentYear,currentMonth+1,weeksData[currentWeek][index]).getMonth())
                setCurrentPage(4)
                setCurrentOption("Day")
            }
        }else if (weeksData[currentWeek][index] > 20) {
            // console.log((currentYear)+"-"+(currentMonth+1)+"-"+weeksData[currentWeek][index])
            setDayCalendarTasks(tasksData[currentYear+"-"+(currentMonth+1)+"-"+weeksData[currentWeek][index]])
            setDay(new Date(currentYear,currentMonth,weeksData[currentWeek][index]).getDay())
            setDate(weeksData[currentWeek][index])
            setCurrentMonth(new Date(currentYear,currentMonth,weeksData[currentWeek][index]).getMonth())
            setCurrentPage(4)
            setCurrentOption("Day")
        }
    }
    else{
        // console.log(currentYear+"-"+(currentMonth+1)+"-"+weeksData[currentWeek][index])
        setDayCalendarTasks(tasksData[currentYear+"-"+(currentMonth+1)+"-"+weeksData[currentWeek][index]])
        setDay(new Date(currentYear,currentMonth,weeksData[currentWeek][index]).getDay())
        setDate(weeksData[currentWeek][index])
        setCurrentMonth(new Date(currentYear,currentMonth,weeksData[currentWeek][index]).getMonth())
        setCurrentPage(4)
        setCurrentOption("Day")
    }
  }
  

  const changeMonthWeek = (change) => {
    const totalDays = []
    const nextMonth = addMonths(currentDay, change)
    const previousMonth = addMonths(currentDay,change===1?0:change)
    const endDate1 = endOfMonth(previousMonth).getDate()
    setCurrentDate(nextMonth)
    setCurrentMonth(nextMonth.getMonth())
    setCurrentYear(nextMonth.getFullYear())
    const startDate = startOfMonth(nextMonth)
    const endDate = endOfMonth(nextMonth)
    const numDays = (differenceInDays(endDate,startDate)+1)
    const prefixDays = (startDate.getDay())
    const suffixDays = (6 - endDate.getDay())
    const prefix = (Array.from(Array(prefixDays).keys())).reverse()
    const numdays = Array.from(Array(numDays).keys())
    const suffix =  Array.from(Array(suffixDays).keys())
    prefix?.map((each)=>{
        totalDays.push(endDate1-each)
    })
    numdays.map(each=>{
        totalDays.push(each+1)
    })
    suffix.map(each=>{
        totalDays.push(each+1)
    })

    const chunkSize = 7;
    const result = [];
    for (let i = 0; i < totalDays.length; i += chunkSize) {
    const chunk = totalDays.slice(i, i + chunkSize);
    result.push(chunk);
    }
    setWeeksData(result)
    setCurrentWeek(change===1?1:result.length-1)

  }

  const setWeeklyPopUp = (dataList,taskDate) => {
        setWeeklyPopUpData(dataList)
        setShowWeeklyPopUp(true)
        // setWeeklyPopupDate(taskDate.split("-")[2])
        // const day = new Date(taskDate).getDay()
        // setWeeklyPopupDay(daysOfTheWeek[day])
  }

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    if (startX && e.changedTouches && e.changedTouches.length > 0) {
      const endX = e.changedTouches[0].clientX;
      const deltaX = endX - startX;
      if (deltaX > 50) {  
        handleSwipeRight();
      }
      else if (-deltaX > 50) {
        handleSwipeLeft();
      }

      setStartX(null);
    }
  };

  const handleSwipeRight = () => {
    
    if(currentPage===3){
        displayPreviousWeek()
    }
    if(currentPage===2){
        displayPreviousMonth()
    }
  };
  const handleSwipeLeft = () => {
    
    if(currentPage===3){
        displayNextWeek()
    }
    if(currentPage===2){
        displayNextMonth()
    }
  };

  const handleUpdateEventDetails = (event) => {
    setSelectedTaskList(selectedTaskList.map((task) => {
        if (task.event_id === event.event_id) {
            return {
                event_name: event.event_name,
                event_id: event.event_id,
                event_category_id: event.event_category_id,
                event_startTime: event?.recurrence?.event_start_time,
                // asset_name: event?.event_entity_category?.split(",")[2],
                asset_name:event?.event_entity_name,
                event_startDate: event?.recurrence?.begin_date,
                event_endDate: event?.recurrence?.end_date,
        
            };
        }else{
            return task;
        }
    }));
  }

    function PerDayEventDetails(props){
        return (
            <Modal keyboard={false} backdrop="static" show={showModal}   centered onHide={() => setShowModal(false)}>
                   <div>
                        <Modal.Header closeButton>
                                <Modal.Title>{popupDate},{popupDay} </Modal.Title>
                        </Modal.Header>
                        <div className="per-day-events-body">
                            <div>
                                <ul>
                                    {popupData.map((each,index)=>{
                                        // const hour = parseInt(each?.time?.split(':')[0]); // Extract and convert hour to integer
                                        // const formattedTime = `${hour === 12 ? 12 : hour % 12}:${each.time.split(':')[1]}`;
                                      
                                        return (
                                            <div>
                                                    <li key={index} className="mt-1">
                                                        <div className="d-flex justify-content-between list">
                                                            <p style={{ color: getEventColor(each.event_name) }}>
                                                                {/* {formattedTime.toUpperCase()} */}
                                                                {each.event_name}</p>
                                                           
                                                        </div>
                                                    </li>
                                            </div>
                                        )
                                    })}
                                </ul>
                            </div>
                            
                        </div>
                   </div>
                </Modal>
        )
    }

   const getEventColor = (eventName) => {
    switch (eventName) {
        case 'Maintenance':
            return '#E9C46A'; 
        case 'AMC':
            return '#2A9D8F'; 
        case 'Daily Checklist':
            return '#F4A261'; 
        case 'PPM':
            return '#264653'; 
        default:
            return '#E76F51'; 
    }
};

const handleView = (each) => {

    navigate('/FacilityManagerWorkLog',{state: {event: each}});
}

const handleEdit = (each) => {
    // setTasksPopup(false)
    const eventId = each.event_id; 
 
    setSelectedEventId(eventId); 
    setModalShow(true);
    setEditEvent(true);
    
    axios.get(base_url + "events?filter_condition=%7B%22event_id%22%3A%22" +  eventId +"%22%7D"
    ).then((response) => {
  
        setEventDetails(response.data.data[0]);
        setModalShow(true);

    })
    .catch((error) => {
        
        setAlertShow(true);
        setAlertType('error');
        setAlertMessage('Error');
        setAlertValue(error?.response?.data.message);
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false);
            }
        }]);
    });
}

const modifyFacilityFilter = (selectedFacilityId) => {
    localStorage.setItem("selected_facility_id", selectedFacilityId);
    const selectedFacility = facilitiesListForNav?.find((facility) => facility.facility_id === selectedFacilityId);
    localStorage.setItem("selected_facility_name", selectedFacility?.name);
    localStorage.setItem("selected_city_name",  selectedFacility?.city);
    setSelectedFacilityId(selectedFacilityId);
    setSelectedFacility(`${selectedFacility?.city}, ${selectedFacility?.name}`);
    setIsFacilitySelected(true);
    setSelectedCategoryIds([]);
    setSelectedEvent([]);
}
   
    return (
    <>
        <>
                      <NavBarTop />
                      <LeftNavBar />
        </>
         {contractApiLoader && <Loader />}
            <div className="calendar_container_div">
                <div className="calender-heading-container">
                    <div >   
                        <h1 className="C-pointer" onClick={()=>{displayTodayMonth()}}>{displayTodayDate}</h1>
                        <div className="mobile-view-month-year">
                        {currentWeek ===  weeksData.length-1 ? 
                            <p onClick={()=>{displayTodayMonth()}}>{monthsOfYear[currentMonth].slice(0,3)}-{(currentMonth===11?monthsOfYear[0]:monthsOfYear[currentMonth+1]).slice(0,3)} {currentYear}</p>
                            :
                            currentWeek === 0 ? 
                            <p onClick={()=>{displayTodayMonth()}}>{(currentMonth===0?monthsOfYear[11]:monthsOfYear[currentMonth-1]).slice(0,3)}-{monthsOfYear[currentMonth].slice(0,3)} {currentYear}</p>
                            :
                            <p onClick={()=>{displayTodayMonth()}}>{monthsOfYear[currentMonth]} {currentYear}</p>
                            }
                        </div>
                    </div>
                    <div className="d-flex gap-4">
                        {currentPage===2 && <div className="calender-heading-card">
                            <div className="d-flex gap-1">
                                <p className="C-pointer" onClick={()=>{displayPreviousMonth()}}><IoArrowUpSharp /></p>
                                <p className="C-pointer" onClick={()=>{displayNextMonth()}}><IoArrowDown /></p>
                            </div>
                            <p> {monthsOfYear[currentMonth]} {currentYear}</p>
                            
                        </div>}
                        {currentPage===3 && <div className="calender-heading-card">
                            <div className="d-flex gap-1">
                                <p className="C-pointer" onClick={()=>{displayPreviousWeek()}}><IoArrowUpSharp /></p>
                                <p className="C-pointer" onClick={()=>{displayNextWeek()}}><IoArrowDown /></p>
                            </div>
                            
                            {currentWeek ===  weeksData.length-1 ? 
                            <p>{monthsOfYear[currentMonth].slice(0,3)}-{(currentMonth===11?monthsOfYear[0]:monthsOfYear[currentMonth+1]).slice(0,3)} {currentYear}</p>
                            :
                            currentWeek === 0 ? 
                            <p>{(currentMonth===0?monthsOfYear[11]:monthsOfYear[currentMonth-1]).slice(0,3)}-{monthsOfYear[currentMonth].slice(0,3)} {currentYear}</p>
                            :
                            <p>{monthsOfYear[currentMonth]} {currentYear}</p>
                            }
                            
                        </div>}
                        {currentPage===4 && <div className="calender-heading-card">
                            <div className="d-flex gap-1">
                                <p className="C-pointer" onClick={()=>{displayPreviousMonth()}}><IoArrowUpSharp /></p>
                                <p className="C-pointer" onClick={()=>{displayNextMonth()}}><IoArrowDown /></p>
                            </div>
                            <p>{date} {monthsOfYear[currentMonth]} {currentYear}</p>
                            
                        </div>}
                        <div className="current-view-options">
                        {availableOption.map((each,index)=>{
                            return (
                                <p key={index} onClick={()=>{changeCurrentPage(each)}} 
                                className={`${each === currentOption?'calender-selectedOption C-pointer':'C-pointer'}`}>{each}</p>//select month/year/filters
                            )
                        })}
                        </div>
                        <div >
                            <select className="current-view-options-sm" value={currentOption} 
                            onChange={(e) => changeCurrentPage(e.target.value)}>
                                {availableOption.map((each, index) => (
                                    <option key={index} value={each} 
                                    className={`${each === currentOption ? 'calender-selectedOption C-pointer' : 'C-pointer'}`}>
                                        {each}
                                    </option>
                                ))}
                            </select>
                        </div>
                        
                    </div>
                    <button 
                        onClick={()=>{handleShow()
                        dispatch(getAssetCategories())
                        dispatch(getEventCategories())
                        dispatch(getAllUsers("Technician"))
                        dispatch(getFacmanfromUsers("Facility Manager"))
                        dispatch(commercialHeadGetAllFacilitiesForNav()) 
                    }}
                        className="schedule-date">
                        <p  className="C-pointer">+</p>
                        <p className="heading">Schedule Event</p>
                    </button>
                    <div onClick={()=>{
                        
                        setModalShow(true)
                    }} className="schedule-date-sm">
                        <p  className="C-pointer">+</p>
                    </div>
                </div>
                {/* dropdown and legend */}
                <div className = ' d-flex justify-content-between'  style ={{marginLeft: "55px", marginTop:'-30px'}}>
                        <div  className='d-flex align-items-right'>
                        <SearchableDropdown
                                    className = "searchable-dropdown2"
                                
                                    options = {facilityList? facilityList:[]} //facilityList is the array of facilities
                                    selectedOption = {selectedfacility}
                                    noOptionsAvailable = "Facility unavailable"
                                    onChange = {(e) => {
                                        setSelectedFacility(e.value)
                                        const selectedFacility = facilitiesListForNav?.find((facility) => `${facility.city}, ${facility.name}` === e.value); 
                                        localStorage.setItem("selected_facility_id", selectedFacility?.facility_id);
                                        localStorage.setItem("selected_facility_name", selectedFacility?.name);
                                        localStorage.setItem("selected_city_name", selectedFacility?.city);

                                        setSelectedFacilityId(selectedFacility?.facility_id);
                                        setIsFacilitySelected(true);
                                        if(selectedCategoryIds.length === 0) {
                                            dispatch(readanCreatedEvent(selectedFacility.facility_id))
                                        } else {
                                            dispatch(readanCreatedEvent(selectedFacility.facility_id, selectedCategoryIds))
                                        }
                                    }}
                                 
                                    placeholder = "Select Facility"
                                    isDisabled = {false}
                                />
                        </div>
                        <div className='d-flex align-items-right gap-3' >
                            
                                        <SearchableDropdown
                                                className = "searchable-dropdown2"
                                                options = {eventCategoryList? eventCategoryList:[]} 
                                                selectedOption = {selectedEvent}
                                                noOptionsAvailable = "Category unavailable"
                                                onChange = {(e) => {
                                                    console.log("options", eventCategoryList)
                                                    console.log("selected option", e.map((option) => option.value))
                                                    setSelectedEvent(e.map((option) => option.value))
                                                    
                                                    const listOfEvents = e.map((option) => option.value)
                                                    if ((listOfEvents?.length === 0) || listOfEvents?.includes('All')) {
                                                        setSelectedCategoryIds([]);
                                                        dispatch(readanCreatedEvent(selectedFacilityId))
                                                    } else {

                                                    const selectedCategoryIds = eventCategory?.filter((category) => listOfEvents.includes(category.category_name)).map((category) => category.event_category_id);
                                                    console.log("selectedCategoryIds", selectedCategoryIds)
                                                    setSelectedCategoryIds(selectedCategoryIds);
                                                    dispatch(readanCreatedEvent(selectedFacilityId, selectedCategoryIds))
                                                    }
                                                }} 
                                                // name = "Event"
                                                placeholder = "Select Event"
                                                isDisabled = {!isFacilitySelected}
                                                isMulti = {true}
                                        />

                        </div>
                        <div className='d-flex  justify-content-end mt-2'>
                            <div class="legend" >
                                <div class="legend-item">
                                    <div class="legend-square category-1"></div>
                                    <span>PPM</span>
                                </div>
                                <div class="legend-item">
                                    <div class="legend-square category-2"></div>
                                    <span>AMC</span>
                                </div>
                                <div class="legend-item">
                                    <div class="legend-square category-3"></div>
                                    <span>Maintenance</span>
                                </div>
                                <div class="legend-item">
                                    <div class="legend-square category-4"></div>
                                    <span>Daily CheckList</span>
                                </div>
                                {/* <div class="legend-item">
                                    <div class="legend-square category-5"></div>
                                    <span>Others</span>
                                </div> */}
                            </div>
                        </div>
                </div>
                <div className="dates-container">
                        {currentPage === 1 && <div>
                            <h1>year</h1>
                        </div>}
                        {currentPage === 2 && <div>
                            <div
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd} 
                                className="month-container">
                                <Table className="month-table">
                                    <thead className="month-container-days">
                                        {daysOfTheWeek.map((each,index)=><tr key={index}>
                                            <td>{each}</td>
                                            </tr>)}
                                    </thead>
                                    <tbody className="month-date-container">
                                        {(Array.from(Array(prefixDays).keys())).reverse().map((each,index)=>{
                                                const nextMonth = addMonths(currentDay, -1)
                                                const endDate1 = endOfMonth(nextMonth).getDate()
                                                const presentDate = String(currentYear+"-"+(currentMonth)+"-"+(endDate1-each))
                                                let taskList 
                                                let newTaskList = {}
                                                if(tasksData[presentDate]){
                                                    taskList =  Object.values(tasksData[presentDate]);
                                                }
                                                return (
                                                    <tr key={`preffix-day-${index}`} className="card preffix-month-card">
                                                        <td className="preffix-month-card-details">
                                                            <p>{endDate1-each}</p>
                                                            {taskList?.length > 0 && 
                                                            <div>
                                                            <div >
                                                                <div className="mt-1">
                                                                <div className={`${isToday(each+1)?'today-task-card':'task-card'}`}>
                                                                    <p className="tasklist-taskname">{taskList[0].event_name}</p>
                                                                    <MdOutlineKeyboardArrowRight />
                                                                </div>
                                                                
                                                                {taskList?.length>1 &&
                                                                <div>
                                                                <div className={`${isToday(each+1)?'today-task-card mt-1':'task-card mt-1'}`}>
                                                                    <p className="tasklist-taskname">{taskList[1].event_name}</p>
                                                                    <MdOutlineKeyboardArrowRight />
                                                                </div>
                                                                {
                                                                    taskList?.length>2?
                                                                    <div className="tasklist-extra">
                                                                        <p className="round">+{taskList.length-2}</p>
                                                                        </div>
                                                                    :
                                                                    <p></p>
                                                                }
                                                                </div>}
                                                            </div> 
                                                            </div>
                                                            <div className="pre-month-view-medium">
                                                            {taskList?.length > 0 && 
                                                                <div>
                                                                    {taskList?.map((task,index)=>{
                                                                        if(index<7){
                                                                            return (
                                                                                <div className="mt-1">
                                                                                    <div  key={index} className={`${isToday(each+1)?'today-task-card':'task-card'}`}>
                                                                                        <p className="tasklist-taskname">{task.event_name}</p>
                                                                                        <MdOutlineKeyboardArrowRight />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                    {
                                                                    taskList?.length>7?
                                                                    <div className="tasklist-extra-md">
                                                                        <p onClick={()=>setPerDayModal(each+1)} className="round">+{taskList.length-7}</p>
                                                                        </div>
                                                                    :
                                                                    <p></p>
                                                                }
                                                                </div>
                                                                
                                                            }
                                                            </div>
                                                            
                                                            </div>
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            {/* current month */}
                                        {(Array.from(Array(numDays).keys())).map((each,index)=>{ 
                                            const presentDate = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(each + 1).padStart(2, '0')}`;

                                            let taskList 
                                            let newTaskList = []
                                            
                                            if(tasksData[presentDate]){
                                                let data = tasksData[presentDate]
                                                data?.map((each)=>{
                                                    // if(each.event_name === 'All'){
                                                    if(newTaskList[each.event_name]){
                                                        newTaskList[each.event_name].push(each)

                                                    }else{
                                                        newTaskList[each.event_name] = []
                                                        newTaskList[each.event_name].push(each)

                                                    }
                                                   
                                                })
                                               if(newTaskList.length!==0) taskList.push(newTaskList)
                                            }
                                          
                                            const taskKeys = Object.keys(newTaskList)
                                           
                                            return (
                                                <tr key={index} className='card'>
                                                    <td  className={`${isToday(each+1)?'month-date-card today-card':'month-date-card'}`}>
                                                        <p onClick={() => dayCalendarView(presentDate)}>{each+1}</p>
                                                        <div>
                                                            {/* {taskList?.length > 0 && 
                                                            <div className="mt-1"> */}
                                                                {/* <div className={`${isToday(each+1)?'today-task-card':'task-card'}`}>
                                                                    <p className="tasklist-taskname">{taskList[0].event_name}</p> 
                                                                   
                                                                    <MdOutlineKeyboardArrowRight />
                                                                </div> */}
                                                                
                                                                {/* {taskList?.length>1 &&
                                                                <div>
                                                                <div className={`${isToday(each+1)?'today-task-card mt-1':'task-card mt-1'}`}>
                                                                    <p className="tasklist-taskname">{taskList[1].event_name}</p>
                                                                    <MdOutlineKeyboardArrowRight />
                                                                </div>
                                                                {
                                                                    taskList?.length>2?
                                                                    <div className="tasklist-extra">
                                                                        <p onClick={()=>setPerDayModal(each+1)} className="round">+{taskList.length-2}</p>
                                                                        </div>
                                                                    :
                                                                    <p></p>
                                                                }
                                                                </div>} */}
                                                            {/* </div>
                                                        } */}
                                                        {newTaskList &&
                                                            <div>
                                                                <div className = 'd-flex gap-2 flex-wrap mt-1'>
                                                                    {taskKeys.map(task=>{
                                                                        return (
                                                                            
                                                                                <div class="legend-item" style={{backgroundColor:getEventColor(task)}}>
                                                                                    <div className = "task-boxes" 
                                                                                    onClick={()=>{
                                                                                       
                                                                                        const data = newTaskList[task]?.map(each=>{
                                                                                     
                                                                                            return {
                                                                                                event_name: each.event_name,
                                                                                                event_id: each.event_id,
                                                                                                event_category_id: each.event_category_id,
                                                                                                event_startTime: each?.recurrence?.event_start_time,
                                                                                                // asset_name: each?.event_entity_category?.split(",")[2],
                                                                                                asset_name:each?.event_entity_name,
                                                                                                event_startDate: each?.recurrence?.begin_date,
                                                                                                event_endDate: each?.recurrence?.end_date,
                                                                                                
                                                                                            }
                                                                                        })
                                                                                        
                                                                                        setTasksPopup(true)
                                                                                        setSelectedTaskList(data)
                                                                                        setSelectedTask(task)
                                                                                        
                                                                                    }}
                                                                                    >{newTaskList[task]?.length}</div>
                                                                                </div>
                                                                        )
                                                                        
                                                                    })}
                                                                 </div>
                                                            </div>
                                                        } 
                                                        </div>
                                                        {/* <div className="month-view-medium">
                                                            {taskList?.length > 0 && 
                                                                <div>
                                                                    {taskList?.map((task,index)=>{
                                                                        if(index<7){
                                                                            return (
                                                                                <div className="mt-1">
                                                                                    <div  key={index} className={`${isToday(each+1)?'today-task-card':'task-card'}`}>
                                                                                        <p className="tasklist-taskname">{task.event_name}</p>
                                                                                        <MdOutlineKeyboardArrowRight />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                    {
                                                                    taskList?.length>7?
                                                                    <div className="tasklist-extra-md">
                                                                        <p onClick={()=>setPerDayModal(each+1)} className="round">+{taskList.length-7}</p>
                                                                        </div>
                                                                    :
                                                                    <p></p>
                                                                }
                                                                </div>
                                                                
                                                            }
                                                        </div> */}

                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {/* next month calendar code */}
                                        {(Array.from(Array(suffixDays).keys())).map((each,index)=>{
                                            const presentDate = String(currentYear+"-"+(currentMonth+2)+"-"+(each+1))
                                            let taskList 
                                            if(tasksData[presentDate]){
                                                taskList =  Object.values(tasksData[presentDate]);
                                            }
                                                return (
                                                    <tr key={`suffic-day-${index}`} className="card preffix-month-card">
                                                        <td className="preffix-month-card-details">
                                                            <p>{each+1}</p>
                                                            {taskList?.length > 0 && 
                                                            <div className="mt-1">
                                                                <div className={`${isToday(each+1)?'today-task-card':'task-card'}`}>
                                                                    <p className="tasklist-taskname">{taskList[0].event_name}</p>
                                                                    <MdOutlineKeyboardArrowRight />
                                                                </div>
                                                                
                                                                {taskList?.length>1 &&
                                                                <div>
                                                                <div className={`${isToday(each+1)?'today-task-card mt-1':'task-card mt-1'}`}>
                                                                    <p className="tasklist-taskname">{taskList[1].event_name}</p>
                                                                    <MdOutlineKeyboardArrowRight />
                                                                </div>
                                                                {
                                                                    taskList?.length>2?
                                                                    <div className="tasklist-extra">
                                                                        <p className="round">+{taskList.length-2}</p>
                                                                        </div>
                                                                    :
                                                                    <p></p>
                                                                }
                                                                </div>}
                                                            </div>
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </Table>
                            </div>
                            <div>

                                <PerDayEventDetails />
                            </div>
                        </div>}
                        {currentPage === 3 && <div>
                            <div 
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                                className="week-view-container">
                                <div className="week-view">
                                    <div>
                                        <div className="week-table">
                                            <div className="week-days-con">
                                                    <div>
                                                        <div className="week-day-timeHeader">
                                                            
                                                        </div>
                                                    </div>

                                                {daysOfTheWeek?.map((each,index)=>{
                                                    const today = new Date()
                                                    const isToday = today.getDate()===weeksData[currentWeek][index] && currentMonth === today.getMonth ()
                                                                    && currentYear === today.getFullYear()
                                                    return (

                                                            <div key={index} className="week-con" onClick={() => handleWeekToDayCalendar(index)}>
                                                                <div className="week-con-each-day">
                                                                    <p>{each.slice(0,3)}</p>
                                                                    <p className={`${isToday? 'highlight-today':'highlight'}`}
                                                                    >{weeksData[currentWeek][index]}</p>
                                                                </div>
                                                            </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="week-body-con">
                                                {timeArray.map((each,index)=>{
                                                    const hour = parseInt(each.time.split(':')[0]); 
                                                    const formattedTime = `${hour === 12 ? 12 : hour % 12}:${each.time.split(':')[1]}`;
                                                
                                                    return (
                                                        <div key={index} className="week-body">
                                                            <div className="week-body-time">
                                                                <p key={index}>{formattedTime.toUpperCase()}</p>
                                                            </div>
                                                            <div className="week-body-each-day">
                                                                {weeksData[currentWeek]?.map((each1,index1)=>{
                                                                    let taskDate
                                                                    if(currentWeek>2 && each1<=7){
                                                                        taskDate = currentDay.getFullYear() + '-' + (currentDay.getMonth()+2) + '-' + each1
                                                                    }else if(currentWeek<2 && each1>20){
                                                                        taskDate = currentDay.getFullYear() + '-' + (currentDay.getMonth()) + '-' + each1
                                                                    }
                                                                    else{
                                                                        taskDate = currentDay.getFullYear() + '-' + (currentDay.getMonth()+1) + '-' + each1
                                                                    }
                                                                    const specificTasksData = tasksData[taskDate]
                                                                    const currentTime = each.time.split(":")[0]
                                                                    const currentMeridian = each.time.split(" ")[1]
                                                                    const dataList = specificTasksData?.filter(item=>{
                                                                        const itemTime = item.time.split(":")[0]
                                                                        const itemMeridian = item.time.split(" ")[1] 
                                                                        if(itemTime === currentTime && itemMeridian === currentMeridian){
                                                                            return true
                                                                        }else{
                                                                            return false
                                                                        }
                                                                    })
                                                                    return (
                                                                        // scrollBar pointing to specific time
                                                                        <div key={`${index}-${index1}`} id={index+2} ref={index === 0 ? currentTimeRef : null} className="week-body-each">
                                                                            <div >
                                                                                <div>  
                                                                                        {dataList?.length >0 &&
                                                                                                <div className="week-body-each-task">
                                                                                                    <p className="mx-1">{dataList[0]?.event_name}</p>
                                                                                                </div>
                                                                                            }
                                                                                        {dataList?.length >1 &&
                                                                                                <div className="week-body-each-task">
                                                                                                    <p className="mx-1">{dataList[1]?.event_name}</p>
                                                                                                </div>
                                                                                            }
                                                                                        {dataList?.length > 2 &&
                                                                                            <div className="tasklist-extra">
                                                                                            <p onClick={()=>setWeeklyPopUp(dataList,taskDate)} className="round">+{dataList.length-2}</p>
                                                                                            </div>
                                                                                            }
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            {/* for hour line */}
                                                                            {hourNow===each.value && currentWeek === (getWeekOfMonth(new Date())-1) &&
                                                                            <div className="hour-line" style={{top: `calc((${100*minutesNow}% / ${60}))`}}>
                                                                            </div>}
                                                                            
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>  
                                                    )
                                                })
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* weekly extra tasks pop up */}
                                {/* <Modal keyboard={false} backdrop="static" show={showWeeklyPopUp}   centered onHide={() => setShowWeeklyPopUp(false)}>
                                    <div>
                                            <Modal.Header closeButton>
                                                    <Modal.Title>{weeklyPopupDate},{weeklyPopupDay} </Modal.Title>
                                            </Modal.Header>
                                            <div className="per-day-events-body">
                                                <div>
                                                    <ul>
                                                        {weeklyPopUpData.map((each,index)=>{
                                                            const hour = parseInt(each.time.split(':')[0]); // Extract and convert hour to integer
                                                            const formattedTime = `${hour === 12 ? 12 : hour % 12}:${each.time.split(':')[1]}`;
                                                        
                                                            return (
                                                                <div>
                                                                        <li key={index} className="mt-1">
                                                                            <div key={index} className="d-flex justify-content-between list">
                                                                                <p style={{ color: getEventColor(each.event_name) }}>{formattedTime.toUpperCase()},&nbsp;{each.event_name}</p>
                                                                            </div>
                                                                        </li>
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                
                                            </div>
                                    </div>
                                </Modal> */}
                            </div>
                        </div>
                        }
                        {currentPage === 4 && <div style={{width:"100vw"}}>
                            <FinanceManagerDayCalendar day={day} dayCalendarTasks={dayCalendarTasks}/>
                        </div>}
                        <ScheduleEvent
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            onFileChange={handleFileChange}
                            scheduleType={scheduleType}
                            daysOfTheWeek={daysOfTheWeek}
                            modalData = {eventDetails}
                            handleUpdateEventDetails={handleUpdateEventDetails}
                            modifyFacilityFilter={modifyFacilityFilter}
                        />
                        {/* tasks popup modal */}
                        <Modal keyboard={false} backdrop="static" show={tasksPopup}   centered onHide={() => {
                            setTasksPopup(false)
                            setEventDetails({})
                        }
                            
                            }>
                                    <div>
                                            <Modal.Header closeButton>
                                                    <Modal.Title>{selectedTask}</Modal.Title>
                                            </Modal.Header>
                                            <div className="per-day-events-body">
                                                <div>
                                                <TableComponent
                                                        headers={[
                                                            {name:"Event", type:"text", key:"event_name"},
                                                            {name:"Asset", type:"text", key:"asset_name"},
                                                            {name:"Scheduled At", type:"text", key:"event_startTime"},
                                                            {name:"Action", type:"button", buttons:[
                                                                {name:"view",
                                                                icon:"./images/icons/View.png",
                                                                action: handleView
                                                                },
                                                                {name:"edit",
                                                                icon:"./images/icons/Edit.svg",
                                                                action: handleEdit
                                                                },
                                                            ]}
                                                        ]}
                                                        data={selectedTaskList}
                                                        // loading={isLoading}
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                </Modal>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Calender